.copyright {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 25px;
  font-size: 12px;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  line-height: 1;

  %ring + & {
    color: $white;
    background: $black;

    %footer--home & {
      background: none;
    }
  }

  @include media-breakpoint-up(wide) {
    padding: 70px 25px;
  }

  @include media-breakpoint-down(tablet) {
    padding: $gap;
    font-size: 12px;
  }
}

.copyright__menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  @include media-breakpoint-down(tablet) {
    flex-direction: column;
    order: -1;
    width: 100%;
    margin-bottom: 50px;
  }
}

.copyright__menu-item {
  & + & {
    margin: 0 0 0 20px;
  }

  @include media-breakpoint-down(tablet) {
    text-align: center;

    & + & {
      margin: $gap 0 0;
    }
  }
}

.copyright__link {
  color: currentColor;
  text-decoration: none;

  &--disabled {
    color: $gray;
    pointer-events: none;
  }

  &.active-link {
    cursor: default;
    pointer-events: none;
  }
}

.copyright__text {
  font-weight: bold;
  opacity: 0.5;
}
