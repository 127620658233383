.swiper-scrollbar {
  @extend %swiper-scrollbar;

  bottom: 0;
  background: $white;
  cursor: pointer;

  %swiper-container-horizontal & {
    left: 0;
    width: 100%;
    height: 3px;
  }

  %recommended__images-slider & {
    bottom: 0;
  }

  %slider-is-observable & {
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: transform 0.7s ease-in-out;
  }

  %slider-observable-finished & {
    transform: scaleX(1);
  }

  %slider--reduced & {
    width: calc((var(--max-width) * 0.8));
  }

  @include media-breakpoint-down(phone) {
    width: calc(100% - 60px);
  }
}

.swiper-scrollbar::before {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  width: 100%;
  content: '';
}

.swiper-scrollbar-drag {
  height: 100%;
  background: $black;
}
