.history__items-holder {
  grid-column: 2 / -2;
  height: 0;
  overflow: hidden;
}

.history__items-holder--active {
  height: auto;
}

.history__items-wrapper {
  display: grid;
  grid-template-columns: repeat(8, minmax(80px, 1fr));
  padding: 0 0 50px;
  column-gap: 18px;

  @include media-breakpoint-down(phone) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
}

.history__items {
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  margin: 0 0 50px;
  padding: 0;

  @include media-breakpoint-down(phone) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

.history__item {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(8, minmax(80px, 1fr));
  column-gap: 18px;
  background: $white;

  & + & {
    margin: 3px 0 0;
  }

  @include media-breakpoint-down(phone) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 17px 0;
  }
}

.history__title {
  grid-row: 1 / span 2;
  grid-column: 3 / -1;
  align-self: flex-start;
  height: 100%;
  margin: 0;
  padding: 20px 0 0;
  font-size: 16px;
  font-family: $fontFamily-title;

  @include media-breakpoint-down(phone) {
    margin: 8px 0 12px;
    padding: 0;
    font-size: 15px;
  }
}

.history__image-holder {
  position: relative;
  grid-row: 1 / -1;
  grid-row-start: 1;
  grid-column: 1 / span 2;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  @include media-breakpoint-down(phone) {
    order: -1;
    width: 100%;

    &::before {
      padding: percentage(80 / 262) 0 0;
    }
  }
}

.history__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.history__info-list {
  grid-column: 3 / -1;
  align-self: flex-end;

  %history-summary & {
    grid-column: 1;
    align-self: unset;
  }

  @include media-breakpoint-down(phone) {
    align-self: flex-start;
  }
}

.history__info-termin {
  color: $gray;
  font-size: 14px;

  @include media-breakpoint-down(phone) {
    font-size: 13px;
    line-height: 20px;
  }
}

.history__info-desc {
  color: $black;
  font-family: $fontFamily-title;

  @include media-breakpoint-down(phone) {
    font-size: 13px;
    line-height: 20px;
  }
}

.history__summary {
  @extend %history-summary;

  display: flex;
  flex-direction: column;
  grid-column: -1 / -4;
}

$history-expand-pseudo-size: 14px;

.history__expand {
  @include text-replace;

  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  background: none;
  border: 0;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    box-sizing: border-box;
    width: $history-expand-pseudo-size;
    height: $history-expand-pseudo-size;
    margin: ($history-expand-pseudo-size / -3) 0 0
      ($history-expand-pseudo-size / -2);
    border-color: $black;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(135deg);
    transform-origin: 50% 50%;
    transform-origin: center center;
    content: '';
  }
}
