.account__discount {
  align-self: flex-end;
  max-width: 120px;
  margin: auto 0 0;

  @include media-breakpoint-down(tablet) {
    margin: 20px 0 0;
  }

  @include media-breakpoint-down(phone) {
    position: absolute;
    top: 100px;
    right: 0;
    margin: 0;
  }
}

.account__discount-value {
  font-weight: bold;
  font-size: 46px;
  font-family: $fontFamily-title;
  line-height: 50px;

  @include media-breakpoint-down(phone) {
    font-size: 36px;
  }
}

.account__discount-text {
  font-size: 14px;

  @include media-breakpoint-down(phone) {
    font-size: 12px;
  }
}
