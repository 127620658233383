%move-left {
  transform: translateX(50%);
  transition-timing-function: cubic-bezier(0.02, 0, 0, 1);
  transition-duration: 0.8s;
  transition-property: transform;

  %menu-is-active & {
    transform: translateX(0);
  }
}

.menu {
  @extend %menu;
  @include stretch(fixed);

  left: 100%;
  z-index: 50;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 1;
  background: $gray-200;
  clip-path: inset(0 0);
  will-change: left;

  &::before,
  &::after {
    flex-shrink: 0;
    width: 100%;
    content: '';
  }

  &::before {
    min-height: $header-height;
  }

  &--active {
    @extend %menu-is-active;

    // left: 0;
  }

  &--animating {
    @extend %menu-is-animating;
  }

  @include media-breakpoint-down(tablet) {
    &::before {
      min-height: $header-phone-height;
    }
  }
}

.menu__inner {
  @include layout-full;

  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: space-around;
  max-width: 100%;
  padding: 20px 0 20px 3%;
  overflow: auto;

  @include media-breakpoint-down(tablet) {
    display: block;
    width: unset;
    margin-left: 0;
    padding: 20px 0;
    padding-top: 20px;
  }
}

.menu__content {
  @include media-breakpoint-up(desktop) {
    width: calc(var(--max-width) * 0.35);
    margin-left: 35%;
  }
}

.menu__footer {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @include media-breakpoint-up(desktop) {
    position: relative;
    bottom: 4%;
    align-items: center;
    width: 97%;
    max-width: 100%;
  }

  @include media-breakpoint-down(tablet) {
    flex-direction: column;
  }
}

.menu__profile-teaser {
  @extend %menu__profile-teaser;
  @extend %move-left;

  width: 70%;

  @include media-breakpoint-down(tablet) {
    %menu & {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

.menu__list {
  @extend %menu__list;
  @extend %move-left;

  width: 100%;
  margin: 0 0 60px;
  padding: 0;
  color: $black;
  font-weight: bold;
  font-size: 28px;
  font-family: $fontFamily-title;
  list-style: none;

  @include media-breakpoint-down(tablet) {
    width: calc(100% - 30vw);
    margin: 0 0 40px 30vw;
    font-size: 15px;
  }
}

.menu__item {
  & + & {
    margin-top: 5%;
  }

  @include media-breakpoint-down(tablet) {
    & + & {
      margin-top: 25px;
    }
  }
}

$menuCircle-width: 25%;

.menu__circle {
  position: fixed;
  top: 30%;
  left: 2%;
  z-index: 50;
  width: $menuCircle-width;
  opacity: 1;
  transition: opacity $UITransitionDefault;
  pointer-events: none;
  fill: $black;

  &--hidden {
    opacity: 0;
  }

  @include media-breakpoint-up(wide) {
    top: 25%;
  }

  @include media-breakpoint-down(tablet) {
    left: 2px;
    width: 30vw;
    margin-left: 0;
  }

  @include media-breakpoint-down(phone) {
    top: 330px;
  }
}

.menu__circle-text {
  fill: $black;
}

.menu__link {
  color: currentColor;
  font-size: txf-relativeValue(36px, $unit: 'vw');
  text-decoration: none;
  transition: $UITransitionDefault;

  &:hover {
    padding-left: 20px;
  }

  &.active {
    padding-left: 0;
    cursor: default;
    pointer-events: none;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}

.menu__contact {
  @extend %move-left;

  margin-left: 12%;
  color: $black;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  text-transform: uppercase;
  text-decoration: none;

  %menu__list ~ & {
    margin-left: 300px;
  }

  & + & {
    margin-left: 70px;
  }

  @include media-breakpoint-down(tablet) {
    width: calc(100% - 30vw);
    margin-left: 30vw;
    font-size: 13px;

    %menu__list ~ & {
      margin-left: 30vw;
    }

    & + & {
      margin-top: 20px;
      margin-left: 30vw;
    }
  }
}

$footnote-width: 5%;

.menu__language-switch {
  @extend %move-left;

  margin: 0 calc((100% - var(--max-width)) / 2 + #{$footnote-width}) 0 auto;
  font-size: txf-relativeValue(16px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    width: calc(100% - 30vw);
    margin: 20px 0 0 30vw;
    font-size: 13px;
  }
}

.menu__apps {
  @extend %menu__apps;
  @extend %move-left;

  grid-template-columns: repeat(2, 1fr);

  @include media-breakpoint-down(tablet) {
    position: static;
    order: 1;
    margin: 40px auto 0 30vw;
  }

  @include media-breakpoint-down(phone) {
    margin: 40px auto 0;
  }
}
