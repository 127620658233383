.button {
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 45px;
  padding: 0 40px;
  color: $black;
  font-weight: bold;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-title;
  text-align: center;
  text-decoration: none;
  background: none;
  border: 0.3px solid $gray-500;
  cursor: pointer;
  transition: $UITransitionDefault;

  & + & {
    margin-left: 20px;
  }

  &:disabled {
    color: $gray-500;
    cursor: not-allowed;
  }

  &--selected {
    color: $white;
    background: $black;
    border-color: $black;
  }

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;

    // stylelint-disable max-nesting-depth

    &:disabled {
      color: $gray-500;
      background: none;
      border-color: $gray-500;
    }
    // stylelint-enable max-nesting-depth
  }

  &--captcha {
    align-self: flex-start;
    margin-top: 20px;
  }

  &[type='submit']:not(.button--captcha) {
    align-self: flex-end;
  }

  %basket-register__holder & {
    align-self: flex-start;
  }

  @include media-breakpoint-up(wide) {
    height: 55px;
  }

  @include media-breakpoint-up(extra-wide) {
    height: 65px;
    font-size: txf-relativeValue(14px, $unit: 'vw');
  }

  @include media-breakpoint-down(tablet) {
    height: 50px;
    font-size: 16px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;

    %menu__profile-teaser & {
      width: auto;
    }

    %foot-teaser__footer & {
      width: 60%;
    }

    &--captcha {
      margin-top: 20px;
    }
  }
}

.button--start {
  align-self: flex-start;
}

.button--text {
  padding: 0;
  color: $gray-500;
  font-weight: normal;
  font-family: $fontFamily-text;
  background: none;
  border: 0;

  &:hover {
    @extend %button--text-hover;

    color: $black;
    background: none;
  }
}

// Size Modifiers

.button--medium {
  padding: 0 50px;

  @include media-breakpoint-down(phone) {
    padding: 0 35px;
  }
}

.button--large {
  height: 60px;
  padding: 0 60px;
  font-size: 20px;
}

.button--full {
  width: 100%;
}

// Phone Size Modifiers

.button--phone-small {
  @include media-breakpoint-down(phone) {
    height: 40px;
    padding: 15px;
    font-size: 13px;
  }
}

// Color Modifiers

.button--white {
  color: $white;
}

.button--pale {
  color: $gray-500;
}

.button--invert {
  color: $white;
  background: $black;
  border-color: $black;

  &:hover {
    color: $black;
    background: none;
  }
}

// Active Modifiers

.button--active-white {
  input:checked + & {
    color: $black;
    background: $white;
    border-color: $white;
  }
}
