.account--form {
  @extend %account--form;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // width: 100%;
  height: auto;
  padding: 0 20px 0 0;

  [type='submit'] {
    margin: 0 0 0 auto;
  }

  > %form-row {
    align-items: baseline;
    width: 50%;

    // stylelint-disable-next-line
    &:nth-of-type(even) {
      padding: 0 0 0 100px;
    }
  }
}
