$footnote-rotateDegree: 90deg;

.footnote {
  @extend %footnote;

  position: fixed;
  right: $layout-horizontalIndent;
  bottom: calc(190px + 4%);
  z-index: 50;
  display: flex;
  line-height: 60px;
  transform: translate(100%, 100%) rotate($footnote-rotateDegree)
    translateY(calc(-100% - #{$gap}));
  transform-origin: top left;
  opacity: 1;
  transition: $UITransitionDefault;
  pointer-events: none;

  %body--animated & {
    opacity: 0;
  }

  @include media-breakpoint-up(wide) {
    bottom: calc(190px + 10%);
  }

  @include media-breakpoint-down(tablet) {
    display: none;
  }
}

.footnote__search-form {
  transform: rotate($footnote-rotateDegree * -1);
  pointer-events: all;
}
