.audio-player {
  width: 100%;
  margin: 40px 0 0;

  & + & {
    margin: 20px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    margin: 20px 0 0;
  }
}
