.payment__section {
  padding: 0 90px 0 28%;

  h1 {
    margin-bottom: 50px;
    font-weight: 800;
    font-size: 100px;

    @include media-breakpoint-down(phone) {
      margin-bottom: 30px;
      font-size: 26px;
    }
  }

  h2 {
    margin-top: 40px;
    font-size: 28px;

    @include media-breakpoint-down(phone) {
      margin: 30px 0 5px;
      font-size: 15px;
    }
  }

  p {
    line-height: 26px;

    @include media-breakpoint-down(phone) {
      line-height: 18px;
    }
  }

  @include media-breakpoint-down(phone) {
    padding: 0 0 0 28%;
  }
}

.payment__info-block {
  @extend %payment-info-block !optional;

  display: flex;
  width: 100%;

  & + & {
    margin-top: 10px;
  }

  &.separator {
    margin-top: 40px;
  }

  @include media-breakpoint-down(phone) {
    flex-direction: column;
  }
}

.payment__info-title {
  width: 35%;
  min-width: 35%;
  font-weight: 800;

  @include media-breakpoint-down(phone) {
    width: 100%;
    min-width: 100%;
    font-weight: 800;
  }
}

.payment__section-title {
  margin: 50px 0 40px;
  font-size: 62px;

  @include media-breakpoint-down(phone) {
    margin: 30px 0 20px;
    font-size: 26px;
  }
}
