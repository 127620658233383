.map {
  min-height: 215px;

  %section-is-observable & {
    transform: translateY(50px);
    opacity: 0;
    transition: transform $UITransitionSlowest $UITransitionMedium,
      opacity $UITransitionSlowest $UITransitionMedium;
  }

  %section-is-intersected & {
    transform: translateY(0);
    opacity: 1;
  }

  [class*='ymaps-2'][class*='-ground-pane'] {
    filter: grayscale(1);
  }

  @include media-breakpoint-up(wide) {
    %contacts & {
      min-height: 300px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    %contacts & {
      min-height: 400px;
    }
  }
}
