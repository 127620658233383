@use 'sass:math';

// Set font-size Property
$txgl-fontSizes: (
  h1: 64px,
  h1-max: 115px,
  h1-phone: 28px,
  h2: 32px,
  h2-phone: 16px,
  h3: 26px,
  h3-phone: 16px,
  h4: 21px,
  h4-phone: 16px,
  h5: 18px,
  h5-phone: 16px,
  h6: 18px,
  h6-phone: 16px,
  small: 11px,
  large: 26px,
);

// Relative Unit Multiplier

@function pow($x, $n) {
  $ret: 1;

  @if $n >= 0 {
    @for $i from 1 through $n {
      $ret: $ret * $x;
    }
  } @else {
    @for $i from $n to 0 {
      $ret: $ret / $x;
    }
  }

  @return $ret;
}

@function to-fixed($float, $digits: 2) {
  $sass-precision: 5;

  @if $digits > $sass-precision {
    @warn "Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now."
    + "The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`."
    + "See https://github.com/sass/sass/issues/1122 for further informations.";
  }

  $pow: pow(10, $digits);
  @return round($float * $pow) / $pow;
}

@function txf-decimalRound($number, $digits: 0, $mode: round) {
  $n: 1;

  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }

  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return round($number * $n) / $n;
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

@function txf-relativeValueMultiplier($unit) {
  @if ($unit == 'vw') or ($unit == 'vh') or ($unit == '%') {
    @return 100;
  } @else {
    @return 1;
  }
}

// Convert to Relative Value

$txgl-baseWidth: 1200px;

@function txf-relativeValue($value, $base: $txgl-baseWidth, $unit: '%') {
  @return #{to-fixed($value / $base, 3) * txf-relativeValueMultiplier($unit)}#{$unit};
}

$txgl-fontSize: 1px;

@mixin txmx-fontSize(
  $newFontSize,
  $baseFontSize: $txgl-fontSize,
  $fallback: false
) {
  $size: 0;
  @if type-of($newFontSize) == number {
    $size: $newFontSize;
  } @else {
    $size: map-get($txgl-fontSizes, $newFontSize);
  }
  @if $fallback == true {
    font-size: $size;
  }

  font-size: $baseFontSize;
  // stylelint-disable-next-line
  font-size: #{txf-relativeValue($size, $baseFontSize, 'rem')};
}
