.hero {
  @extend %restaurant-hero;
  @include layout;

  position: relative;
  margin-bottom: 50px;
  background-color: $gray;
  transform: translate(100%);
  transition: transform 1.2s ease-in-out;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 45% 0 0;
    content: '';
  }

  &--visible {
    @extend %restaurant-hero-is-visible;

    transform: translate(0);
  }

  &.page {
    transform: translate(0);
  }

  @include media-breakpoint-up(wide) {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-down(phone) {
    &::before {
      padding: 164% 0 0;
    }
  }
}

.hero__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 105px;

  &::before {
    @include stretch;

    z-index: 1;
    background: rgba($black, 0.5);
    content: '';
  }

  &::after {
    order: -1;
    width: 100%;
    content: '';
  }

  // stylelint-disable-next-line
  & > *:not(img) {
    z-index: 2;
  }

  @include media-breakpoint-down(phone) {
    padding: 70px 25px 40px;
  }
}

.hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__logo {
  position: relative;
  z-index: 2;
  order: -1;
  max-width: 15%;
  height: auto;
  max-height: 70%;
  margin-bottom: $gap;
  object-fit: contain;
  transform: translate(70px);
  opacity: 0;
  transition: transform $UITransitionSlowest ease-in-out 0.6s,
    opacity $UITransitionDefault 0.6s;

  %restaurant-hero-is-visible & {
    transform: translate(0);
    opacity: 1;
  }

  @include media-breakpoint-down(phone) {
    max-width: 80px;
    height: auto;
    max-height: 35%;
    margin-bottom: 120px;
    transform: translate(0);

    %restaurant-hero-is-visible & {
      opacity: 1;
    }
  }
}

.hero__button {
  position: relative;
  transform: translate(90px);
  transition: transform $UITransitionSlowest ease-in-out 0.6s,
    $UITransitionDefault;

  %restaurant-hero-is-visible & {
    transform: translate(0);
  }

  @include media-breakpoint-down(tablet) {
    width: 30%;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    transform: translate(0);
    opacity: 0;
    transition: opacity $UITransitionSlow 0.6s;

    %restaurant-hero-is-visible & {
      opacity: 1;
    }

    & + & {
      margin: 20px 0 0;
    }
  }
}
