.promo-form {
  @extend %promo-form;

  display: flex;

  @include media-breakpoint-down(phone) {
    flex-direction: column;
  }
}

.promo-form__button {
  height: 40px;
  margin: auto 0 0;

  @include media-breakpoint-down(phone) {
    margin: 10px 0 0;
  }
}
