@mixin text-stroke($color: $black) {
  @media (hover) {
    color: transparent;
    text-decoration: none;
    transition: color $UITransitionDefault;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $black;

    &:hover {
      color: $color;
    }

    @include media-breakpoint-down(tablet) {
      -webkit-text-stroke: 0.3px;
    }
  }
}

@mixin text-stroke-reversed($color: $black) {
  @media (hover) {
    color: $color;
    text-decoration: none;
    transition: color $UITransitionDefault;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $black;

    &:hover {
      color: transparent;
    }

    @include media-breakpoint-down(tablet) {
      -webkit-text-stroke: 0.3px;
    }
  }
}
