.event {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event__logo {
  width: auto;
  max-width: 120px;
  height: auto;
  max-height: 120px;
  margin: 30px auto;

  @include media-breakpoint-up(wide) {
    max-width: 200px;
    height: auto;
    max-height: 200px;
  }
}

.event__info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &-news {
    min-width: unset;
  }

  @include media-breakpoint-down(tablet) {
    margin-bottom: 30px;
  }
}

.event__address {
  margin: 0 0 20px;
}

.event__divisor {
  position: relative;
  display: block;
  width: 1px;
  height: 50px;
  margin: 0 20px;
  background-color: $gray-450;
}

.event__feature {
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.event__title {
  margin: 0 0 30px;

  @include media-breakpoint-down(phone) {
    margin-bottom: 20px;
  }
}

.event__slide {
  max-width: 50%;

  @include media-breakpoint-down(phone) {
    max-width: 100%;
  }
}

.event-slider-image-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.event__description {
  @include layout;

  margin-top: 0;
  margin-bottom: 30px;
  padding: 0 125px;

  @include media-breakpoint-down(phone) {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }
}

.event__image-holder {
  width: 410px;
  height: 510px;
  margin-bottom: 50px;

  @include media-breakpoint-down(phone) {
    width: 100%;
  }
}

.event__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event__button {
  margin-bottom: 50px;
}

.event__socials {
  margin: 40px auto 0;
}
