.order-counter__holder {
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid $gray-400;

  @include media-breakpoint-down(phone) {
    justify-content: space-between;
  }
}

.order-counter__count {
  width: 2em;
  text-align: center;
}

$order-counter-button-width: 40px;
$order-counter-button-height: 40px;

%order-counter-button {
  @include text-replace;

  position: relative;
  width: 40px;
  height: 100%;
  padding: 0;
  overflow: hidden;
  color: $gray;
  background: none;
  border: 0;
  transition: color $UITransitionDefault;

  &:hover {
    color: $black;
  }
}

.order-counter__decrease {
  @extend %order-counter-button;

  margin: 0 10px 0 0;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 10px;
    height: 1px;
    background-color: currentColor;
    transform: translate(-50%, -50%);
    content: '';
  }
}

.order-counter__increase {
  @extend %order-counter-button;

  margin: 0 0 0 10px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 10px;
    height: 1px;
    background-color: currentColor;
    transform: translate(-50%, -50%);
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 10px;
    height: 1px;
    background-color: currentColor;
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: 50% 50%;
    transform-origin: center center;
    content: '';
  }
}
