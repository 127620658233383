.home-slider-preview {
  @extend %home-slider-preview;

  @include media-breakpoint-down(phone) {
    box-sizing: border-box;

    .slide {
      overflow: hidden;
    }

    &.swiper-container-horizontal {
      box-sizing: border-box;
      width: 100%;
      padding: 0 30px 20px;
    }
  }
}

.home-slider-preview-slide {
  max-width: 100%;

  &::after {
    position: relative;
    display: block;
    width: 100%;
    padding: 45% 0 0;
    content: '';
  }

  @include media-breakpoint-down(phone) {
    box-sizing: border-box;

    &::after {
      padding: 164.12% 0 0;
    }
  }
}

.slide-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 115px 25% 70px 10%;

  @include media-breakpoint-up(extra-wide) {
    padding-top: 150px;
    padding-bottom: 120px;
  }

  @include media-breakpoint-down(phone) {
    padding: 130px 30px 60px 25px;
  }
}
