.auth-form {
  @extend %auth-form;

  display: flex;
  flex-direction: column;
  grid-column: 4 / span 4;
}

$auth-code-count: 6;
$auth-code-gap: 15px;

.auth-form__code-holder {
  @extend %auth-form__code-holder;

  display: grid;
  grid-template-columns: repeat(#{$auth-code-count}, minmax(20px, 1fr));
  column-gap: $auth-code-gap;
}
