.swiper-wrapper {
  %swiper-container-vertical & {
    @include layout;

    // height: $slider-height-desktop;
  }

  @include media-breakpoint-down(tablet) {
    %swiper-container-vertical & {
      max-width: 100%;
      height: $slider-height-tablet;
      padding: 0;
    }
  }
}
