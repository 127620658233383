.section {
  @extend %section;
  @include layout;

  margin-bottom: $section-gap;

  &--observable {
    @extend %section-is-observable;

    transform: translateY(100px);
    opacity: 0;
    transition: transform 0.8s, opacity $UITransitionSlowest;
  }

  &--intersected {
    @extend %section-is-intersected;

    transform: translateY(0);
    opacity: 1;
  }

  &--column {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-down(tablet) {
    margin-bottom: $section-phone-gap;
  }
}

.section--narrow {
  @include layout-narrow;
}

.section__title {
  z-index: 5;
  margin: 0 0 0.3em;

  @include media-breakpoint-down(tablet) {
    z-index: 15;
  }
}

.section__description {
  margin: 0 0 10px;
}

.section__select {
  margin: 35px 0 20px;
}

.section__search-form {
  margin-bottom: 30px;
}
