.history {
  margin-bottom: 80px;

  @include media-breakpoint-up(wide) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin-bottom: 120px;
  }

  @include media-breakpoint-down(tablet) {
    margin-bottom: 55px;
  }
}

.history__table {
  display: flex;
  flex-direction: column;
}

.history__row {
  position: relative;
  display: grid;
  grid-template-columns: repeat(10, minmax(80px, 1fr));
  column-gap: 18px;
  background: rgba($white, 0.4);
  transition: background-color $UITransitionDefault;

  &--head {
    font-weight: bold;
    background: none;
  }

  & + & {
    margin: 8px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    grid-template-columns: repeat(10, minmax(50px, 1fr));
  }

  @include media-breakpoint-down(phone) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 25px;
    background: $white;
  }
}

.history__row--expanded {
  @include media-breakpoint-down(phone) {
    background-color: rgba($white, 0.4);
  }
}

%history__head {
  line-height: 60px;
  text-align: center;

  ul & {
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    display: flex;
    // padding: 15px 25px;
    line-height: unset;

    & + & {
      margin: 8px 0 0;
    }
  }
}

%history-mobile {
  &--title {
    width: 35%;
    font-weight: bold;
    font-size: 13px;
    text-align: left;
  }

  &-value {
    width: 65%;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
  }
}

.history__row--month-devider {
  @extend %history__head;

  display: grid;
  grid-column: 1 / span 2;
  margin: 20px 0 0;
  font-weight: bold;
  line-height: 30px;

  @include media-breakpoint-down(phone) {
    margin: 0;
  }
}

.history__id {
  @extend %history__head;

  grid-column: 1 / span 2;

  &--title {
    @extend %history-mobile--title;
  }

  &--value {
    @extend %history-mobile-value;
  }
}

.history__date {
  @extend %history__head;

  grid-column: 3 / span 3;

  &--title {
    @extend %history-mobile--title;
  }

  &--value {
    @extend %history-mobile-value;
  }
}

.history__cost {
  @extend %history__head;

  grid-column: 6 / span 2;

  &--title {
    @extend %history-mobile--title;
  }

  &--value {
    @extend %history-mobile-value;
  }
}

.history__status {
  @extend %history__head;

  grid-column: 8 / span 2;

  &--title {
    @extend %history-mobile--title;
  }

  &--value {
    @extend %history-mobile-value;
  }
}
