.restaurant-menu {
  @extend %restaurant-menu;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  // TODO: отрефакторить

  &%section {
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(wide) {
    &%section {
      margin-bottom: 50px;
    }
  }
}

.restaurant-menu__logo {
  @extend %restaurant-menu;

  width: auto;
  max-width: none;
  height: 120px;
  margin: 30px auto;

  @include media-breakpoint-up(wide) {
    margin: 50px auto;
  }

  @include media-breakpoint-down(phone) {
    max-width: 85px;
    height: auto;
  }
}

.restaurant-menu__title {
  margin: 0 0 30px;

  @include media-breakpoint-up(wide) {
    margin-bottom: 70px;
  }

  @include media-breakpoint-down(phone) {
    margin-bottom: 20px;
  }
}
