.footer-marquee-holder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.footer-marquee {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 33%;
  overflow: hidden;
  color: rgba($gray-900, 0.1) !important;
  font-weight: bold;
  font-size: calc(var(--font-size) * 1.45);
  // font-family: $fontFamily-title;

  .overlay {
    display: none;
  }
}
