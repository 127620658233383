.cookies {
  position: fixed;
  right: 34px;
  bottom: 27px;
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: calc(var(--max-width) / 2.1);
  padding: 50px 100px;
  background: $white;

  .more {
    margin: 0 0 0 auto;
    transform: translate(-100%);
  }

  @include media-breakpoint-up(wide) {
    .more {
      transform: translate(-250px);
    }
  }

  @include media-breakpoint-down(phone) {
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 30px;

    > button {
      width: 80px;
    }

    .more {
      transform: translate(-150%);
    }
  }
}

.cookies__title {
  width: 100%;
  font-weight: bold;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: Times, serif;

  @include media-breakpoint-down(phone) {
    font-size: 20px;
  }
}

.cookies__text {
  width: 100%;
  margin: 1em 0 2.5em;
  color: $gray;
  font-size: txf-relativeValue(12px, $unit: 'vw');

  @include media-breakpoint-down(phone) {
    font-size: 12px;
  }
}
