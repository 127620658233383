*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  @include stretchHeight;

  --max-width: var(--max-width);
}

body {
  @include stretchHeight;

  width: 100%;
  margin: 0;
}

.root {
  @include stretchHeight;
}
