$scroll-bar-width: 3px;
$scroll-bar-background: $black;

@mixin styled-scrollbar {
  &::-webkit-scrollbar {
    width: $scroll-bar-width;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $scroll-bar-background;
    border-radius: 0;
  }
}
