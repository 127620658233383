.restaurant-teasers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  line-height: 1.4;
  text-align: center;

  @include media-breakpoint-down(tablet) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 10px;
  }
}

.restaurant-teaser {
  position: relative;
  color: $white;
  font-size: 12px;
  font-size: txf-relativeValue(12px, $unit: 'vw');

  &::after {
    position: relative;
    display: block;
    padding-top: 100%;
    background: $black;
    opacity: 0.25;
    transition: $UITransitionDefault;
    content: '';
  }

  &--observable {
    transform: translate(50px);
    opacity: 0;
    transition: $UITransitionDefault;
  }

  &--visible {
    transform: translate(0);
    opacity: 1;
    transition: transform 0.6s ease-in-out, opacity 0.7s;
  }

  &:hover {
    @extend %restaurant-teaser-hover;

    color: $gray-500;
  }

  &:hover::after {
    opacity: 0.9;
  }

  @include media-breakpoint-down(phone) {
    font-size: 12px;
    transform: translate(0, 20px);

    &--visible {
      transform: translate(0);
    }
  }
}

.restaurant-teaser--active {
  @extend %restaurant-teaser--active;
}

.restaurant-teaser--slide {
  @extend %restaurant-teaser--slide;

  width: 100%;
  height: 100%;

  &::after {
    height: 100%;
    padding: 0;
  }

  &:not(%restaurant-teaser--active)::after {
    opacity: 0;
  }

  %swiper-slide-is-active & {
    @extend %restaurant-teaser--slide-active;
  }
}

.restaurant-teaser__title {
  @extend %restaurant-teaser__title;

  margin: 0 auto 25px;
  font-size: txf-relativeValue(80px, $unit: 'vw');
  text-align: center;

  %restaurant-teaser--slide & {
    color: $white;

    // stylelint-disable-next-line
    a {
      transition: $UITransitionDefault;
      pointer-events: none;

      @media (pointer: coarse) {
        touch-action: none;
      }
    }

    // stylelint-disable-next-line
    &:hover {
      color: $white;
    }

    @include media-breakpoint-down(tablet) {
      font-size: 70px;
    }

    @include media-breakpoint-down(phone) {
      font-size: 36px;
    }
  }

  %restaurant-teaser--slide-active & {
    color: $white;

    // stylelint-disable-next-line
    a {
      color: $white;
      -webkit-text-stroke-width: 0;
      -webkit-text-stroke-color: $white;
      pointer-events: all;

      @media (pointer: coarse) {
        touch-action: auto;
      }
    }
  }
}

.restaurant-teaser__image {
  @include stretch;

  object-fit: cover;
  transition: $UITransitionDefault;

  %restaurant-teaser--slide & {
    opacity: 0;
  }

  %restaurant-teaser--slide-active & {
    opacity: 1;
  }
}

.restaurant-teaser__inner {
  @include stretch;

  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $gap;

  @include media-breakpoint-down(tablet) {
    padding: 15px;
  }
}

.restaurant-teaser__logo {
  position: absolute;
  top: 50%;
  max-width: 50%;
  margin: auto;
  transform: translateY(-50%);
  transition: $UITransitionDefault;
  pointer-events: none;
  will-change: top;

  %restaurant-teaser-hover & {
    top: 20%;
    max-width: 20%;
  }
}

.restaurant-teaser__description {
  max-height: 0;
  margin: auto 0;
  text-align: center;
  transform: translateY(15px);
  transform-origin: 0 0;
  opacity: 0;
  transition: max-height $UITransitionMedium, transform $UITransitionMedium,
    opacity $UITransitionMedium;
  pointer-events: none;

  %restaurant-teaser-hover & {
    max-height: 300px;
    transform: translateY(0);
    opacity: 1;
  }

  %restaurant-teaser--slide & {
    display: block;
    max-height: unset;
    margin: 0;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform $UITransitionMedium $UITransitionSlowest,
      opacity $UITransitionSlow $UITransitionFast;
    pointer-events: all;
  }

  %restaurant-teaser--slide-active & {
    transform: translateY(0);
    opacity: 1;
    transition: transform $UITransitionMedium $UITransitionSlowest,
      opacity $UITransitionSlow $UITransitionSlowest;
  }

  @include media-breakpoint-down(tablet) {
    %restaurant-teaser--slide & {
      font-size: 13px;
    }
  }
}

.restaurant-teaser__phone {
  position: relative;
  color: currentColor;
  text-decoration: none;
}

.restaurant-teaser__more {
  @include stretch;

  text-indent: -9999px;

  %restaurant-teaser--slide & {
    pointer-events: none;
  }

  %restaurant-teaser--slide-active & {
    pointer-events: all;
  }
}
