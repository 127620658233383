.feedback-form {
  @extend %feedback-form;

  margin-top: 35px;
  padding: 0 22% 0 28%;

  @include media-breakpoint-down(tablet) {
    padding: 0;
  }
}
