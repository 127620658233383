.history__actions {
  display: flex;
  grid-column: 1 / span 4;
  align-items: center;

  @include media-breakpoint-down(phone) {
    flex-direction: column;
    order: 10;
    width: 100%;
    margin: 25px 0 0;
  }
}

.history-action {
  & + & {
    margin: 0 0 0 50px;
  }

  @include media-breakpoint-down(phone) {
    & + & {
      margin: 0 0 25px;
    }
  }
}

.history-action__cancel-order {
  font-style: italic;
}

.history-action-rate {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;
  font-family: $fontFamily-title;
  background: none;
  border: 0;

  @include media-breakpoint-down(phone) {
    align-self: flex-start;
    order: -1;
  }
}

.history-rate-icon-holder {
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  border: 1px solid $black;
  border-radius: 50%;
}

.history-rate-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
}
