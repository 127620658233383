.restaurant-menu-teaser {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;
  }
}

.restaurant-menu-teaser__content {
  @include media-breakpoint-down(phone) {
    padding-left: 90px;
  }
}

.restaurant-menu-teaser__title {
  margin: 0 0 40px;

  @include media-breakpoint-up(wide) {
    margin: 0 0 80px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 0 0 100px;
  }

  @include media-breakpoint-down(phone) {
    margin: 0 0 30px;
  }
}

.restaurant-menu-teaser__description {
  margin: 0 0 50px;

  @include media-breakpoint-down(phone) {
    margin: 0 0 30px;
  }
}

.restaurant-menu-teaser__image {
  width: 50%;
  height: 590px;
  margin: 0 65px 0 0;
  object-fit: cover;

  @include media-breakpoint-down(phone) {
    width: 100%;
    height: 320px;
    margin: 0 0 30px;
  }
}
