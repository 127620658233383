.basket-locations-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;

  > li {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
    border: 1px solid $gray-400;

    // stylelint-disable-next-line
    &:hover {
      @extend %basket-location-item-hover;
    }
  }
}

.basket-location-button {
  display: none;
  margin: 0 30px 0 auto;
  font-weight: bold;
  font-family: $fontFamily-title;

  %basket-location-item-hover & {
    display: block;
  }
}

.basket-location--error {
  margin-bottom: 50px;
  color: $red;
}
