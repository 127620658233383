.basket-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  color: $black;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-title;
  font-style: italic;
  background: none;
  border: 0;

  &:hover {
    @extend %basket-link--hover;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 300%;
    height: 100%;
    content: '';
  }

  @include media-breakpoint-down(tablet) {
    font-size: 13px;

    &::after {
      display: none;
    }
  }
}

.basket-link__price {
  position: absolute;
  top: 50%;
  right: -15px;
  white-space: nowrap;
  transform: translate(100%, -50%);

  @include media-breakpoint-down(tablet) {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 10px;
    transform: none;
  }
}

.basket-link__price-value {
  font-weight: bold;
  font-style: normal;
}
