.basket-recomended {
  position: relative;

  > h2 {
    @include text-stroke-reversed;
  }
}

.recommended__expand-button {
  --button-size: 50px;
  @include text-replace;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  width: var(--button-size);
  height: var(--button-size);
  padding: 0;
  background: none;
  border: 0;

  &:hover {
    @extend %recommended-expand-button-hover;
  }

  @include media-breakpoint-down(tablet) {
    --button-size: 35px;
  }
}

.recommended__expand-button-icon {
  --icon-size: 25px;

  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--icon-size);
  height: var(--icon-size);
  margin: calc((var(--icon-size) / -2)) 0 0 calc((var(--icon-size) / -2));
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  transform-origin: center center;
  transition: fill $UITransitionDefault, transform $UITransitionDefault;
  pointer-events: none;
  fill: $gray;

  &--active {
    transform: rotate(0);
  }

  %recommended-expand-button-hover & {
    fill: $black;
  }

  @include media-breakpoint-down(tablet) {
    --icon-size: 20px;
  }
}

.recommended__slider {
  @extend %recommended__slider;
}
