.not-found {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30%;

  div {
    max-width: 45%;
    font-size: 20px;
    line-height: 24px;

    @include media-breakpoint-down(phone) {
      max-width: 100%;
      margin-top: auto;
      margin-bottom: 0;
      font-size: 16px;
      text-align: center;
    }
  }

  a:visited,
  a:focus {
    color: $black;
  }

  a:hover {
    color: $gray;
    transition: all ease-in 0.1s;
  }

  h1 {
    margin: 0;
    color: $gray-200;
    font-size: 310px;
    line-height: 70%;
    text-shadow: -1px -1px 0 $gray, 1px -1px 0 $gray, -1px 1px 0 $gray,
      1px 1px 0 $gray;

    @include media-breakpoint-down(phone) {
      margin: auto;
      font-size: 96px;
      line-height: 1;
      text-align: center;
    }
  }

  @include media-breakpoint-down(phone) {
    height: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
