.header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  height: $header-height;
  overflow: hidden;
  background: $gray-200;
  opacity: 1;
  transition: $UITransitionDefault;

  %body--animated & {
    opacity: 0;
  }

  @include media-breakpoint-up(wide) {
    height: $header-wide-height;
  }

  @include media-breakpoint-down(tablet) {
    height: $header-phone-height;
  }
}

.header__inner {
  @include layout;

  display: flex;
  align-items: center;

  a:first-of-type {
    margin: 0 0 0 auto;
  }

  a:nth-last-of-type(2) {
    margin-right: 55px;
  }
}

.header__establishment-logo {
  max-width: 100px;
  height: auto;
  max-height: $header-height - 20px;
  margin: 0 70px;

  @include media-breakpoint-up(wide) {
    max-height: $header-wide-height - 20px;
    margin: 0 100px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 0 150px;
  }

  @include media-breakpoint-down(tablet) {
    max-height: $header-phone-height - 5px;
    margin: 0;
  }
}

.header__button {
  opacity: 0.4;

  &--active {
    opacity: 1;
  }

  @include media-breakpoint-down(tablet) {
    display: none;
  }
}

.header__restaurant-button {
  width: 22%;
  opacity: 1;
}

.header__basket-link--disabled {
  cursor: default;
}

.header__delivery-button {
  display: none;

  @include media-breakpoint-down(tablet) {
    display: block;
    margin: 0 auto;
  }
}

.header__favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  border: 1px solid $gray-500;
  border-radius: 50%;

  &:hover {
    @extend %header-favorite-hover;

    background: $black-700;
    border: 1px solid $black-700;
  }
}

.header__favorite-icon {
  display: none;

  %header-favorite-hover & {
    display: block;
  }

  &_black {
    display: block;

    %header-favorite-hover & {
      display: none;
    }
  }
}
