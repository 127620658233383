.home-restaurants-video-holder {
  position: relative;
  z-index: -1;
  width: 100%;
  overflow: hidden;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 31.3% 0 0;
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.6);
    content: '';
  }

  @include media-breakpoint-down(phone) {
    height: 170px;

    &::before {
      display: none;
    }
  }
}

.home-restaurants-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 2s;

  &--hidden {
    opacity: 0;
  }

  @include media-breakpoint-down(tablet) {
    transition: opacity 0.4s;
  }
}
