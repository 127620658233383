.main-head {
  @extend %main-head;
  @include layout;

  z-index: 1;
  padding: 0 0 0 28%;

  &%section {
    margin-bottom: 55px;
  }

  %restaurant-hero + & {
    margin-bottom: 300px;

    @include media-breakpoint-up(wide) {
      margin-bottom: 500px;
    }
  }

  @include media-breakpoint-down(tablet) {
    padding: 0 0 0 30vw;

    &-activities {
      padding: 0;
    }

    &%section {
      margin-bottom: 25px;
    }
  }

  @include media-breakpoint-down(phone) {
    padding: 0;
  }
}

.main-head__title-holder {
  position: relative;
}

.main-head__title {
  margin: 0 0 30px;

  @include media-breakpoint-up(wide) {
    margin: 0 0 70px;
  }

  @include media-breakpoint-down(tablet) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(phone) {
    font-size: 42px;
  }
}

.main-head__branches {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @include media-breakpoint-up(wide) {
    margin-top: 70px;
  }

  @include media-breakpoint-down(tablet) {
    position: relative;
    top: 0;
    padding: 0;
  }
}

.main-head__list {
  margin-top: 15px;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  list-style: none;

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-down(tablet) {
    padding: 0;
    font-size: 14px;
  }
}

.main-head__point {
  a {
    @extend %main-head__point-link;

    color: inherit;
    text-decoration: none;
  }
}

%main-head__point-link:hover {
  color: $gray-500;
}

.main-head__description {
  @include media-breakpoint-up(tablet) {
    margin-right: 45%;
  }
}
