.language-switch {
  display: inline-block;
}

.language-switch__link {
  padding: 0;
  color: $gray-500;
  text-decoration: none;
  background: none;
  border: 0;
  transition: $UITransitionDefault;

  &:hover {
    color: $black;
  }

  & + & {
    margin-left: 10px;
  }
}

.language-switch__link--active {
  color: $black;
}
