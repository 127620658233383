.form-checkbox {
  $self: &;
  @extend %form-checkbox;

  %form-row-content-column & {
    @extend %form-checkbox-content-column;

    // stylelint-disable-next-line
    & + #{$self} {
      margin: 20px 0 0;
    }
  }
}

.form-checkbox--error {
  @extend %form-checkbox-error;

  color: $error-color;
}

.agreement-checkbox {
  width: 75%;
  margin: 0 0 0 auto;
  padding: 0 0 0 15px;
}

.form-checkbox__label {
  position: relative;
  display: block;
  min-height: 22px;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  line-height: 22px;
  cursor: pointer;

  &--aside {
    padding: 0 0 0 30px;
    // stylelint-disable-next-line
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 22px;
      height: 22px;
      background: $white;
      border: 1px solid $gray;
      border-radius: 50%;
      content: '';
    }

    // stylelint-disable-next-line
    &::after {
      position: absolute;
      top: 11px;
      left: 11px;
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      margin: -6px 0 0 -6px;
      background: $black;
      border-radius: 50%;
      transform: scale(0);
      transition: transform $UITransitionDefault;
      content: '';
    }

    // stylelint-disable-next-line
    input:checked + &::after {
      transform: scale(1);
    }

    @include media-breakpoint-up(wide) {
      padding: 0 0 0 60px;
      line-height: 35px;

      &::after {
        width: 25px;
        height: 25px;
      }

      &::before {
        width: 35px;
        height: 35px;
      }
    }

    @include media-breakpoint-down(tablet) {
      font-size: 14px;
    }
  }

  %form-checkbox-content-column & {
    font-size: txf-relativeValue(12px, $unit: 'vw');
  }

  %form-row ~ & {
    margin: 0 0 0 18px;
  }

  %form__row--error &::before {
    border-color: $error-color;
  }

  %form-checkbox-error &::before {
    border-color: $red;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;

    %form-checkbox-content-column & {
      font-size: 14px;
    }
  }
}

.form-checkbox__label--square {
  font-size: txf-relativeValue(12px, $unit: 'vw');

  &::before,
  &::after {
    border-radius: 0;
  }

  &::after {
    width: 16px;
    height: 10px;
    margin: -8px 0 0 -8px;
    background: none;
    border-color: $black;
    border-style: solid;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    transform-origin: center center;
    opacity: 0;
    transition: opacity $UITransitionDefault;
  }

  input:checked + &::after {
    transform: rotate(-45deg);
    opacity: 1;
  }

  %form__row--error & {
    color: $error-color;
  }

  @include media-breakpoint-up(wide) {
    &::after {
      width: 25px;
      height: 15px;
      margin: -7px 0 0 -5px;
      border-width: 0 0 4px 4px;
    }
  }

  @include media-breakpoint-down(tablet) {
    font-size: 12px;
  }
}
