.breadcrumbs {
  @include layout;

  display: flex;
  margin-top: 0;
  margin-bottom: 70px;
  padding: 0;
  font-weight: bold;
  font-size: txf-relativeValue(17px, $unit: 'vw');
  font-family: $fontFamily-title;
  font-style: italic;
  list-style: none;

  @include media-breakpoint-up(wide) {
    margin-bottom: 70px;
  }

  @include media-breakpoint-down(tablet) {
    display: none;
  }
}

.breadcrumbs__item {
  &::after {
    margin-right: 0.3em;
    content: ' /';
  }

  &:last-child {
    @extend %breadcrumbs__item-last;
  }

  &:last-child::after {
    content: none;
  }
}

.breadcrumbs__link {
  color: $gray-500;

  %breadcrumbs__item-last & {
    color: $black;
  }
}

.breadcrumbs__text {
  margin: 0;
  cursor: default;
}
