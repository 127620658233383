@mixin layout {
  width: var(--max-width);
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-down(tablet) {
    max-width: calc(100% - #{$layout-tablet-horizontalIndent * 2});
  }

  @include media-breakpoint-down(phone) {
    max-width: calc(100% - #{$layout-phone-horizontalIndent * 2});
  }
}

@mixin layout-full {
  box-sizing: content-box;
  max-width: var(--max-width);
  padding: 0 (calc((100vw - var(--max-width)) / 2));
  overflow: hidden;
}

@mixin layout-narrow {
  max-width: 520px;

  @include media-breakpoint-down(tablet) {
    max-width: calc(100% - #{$layout-tablet-horizontalIndent * 2});
  }

  @include media-breakpoint-down(phone) {
    max-width: calc(100% - #{$layout-phone-horizontalIndent * 2});
  }
}
