.binding-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.binding-item {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  background: $white;

  & + & {
    margin: 8px 0 0;
  }
}
