.select {
  height: 40px;
  font-weight: 500;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  list-style: none;
  background: none;
  border: 1px solid $gray-400;
  cursor: pointer;
  appearance: none;

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    %form & {
      width: 100%;
    }
  }
}
