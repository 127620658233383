@keyframes rotateIconMore {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.more-icon-rotate {
  transform-origin: 50% 50%;
  transform-origin: center center;
  transition: opacity 0.2s;
  animation: rotateIconMore 50s infinite;
}
