.feasts-holder {
  width: 100vw;
  padding: 0 calc((100vw - (var(--max-width))) / 2);
  overflow: hidden;
}

.feasts {
  @extend %section;
  @extend %fiests;

  max-height: 655px;
  margin: auto;
  margin-bottom: 350px;
  padding: 125px 100px 0;
  background-color: $white;

  & + & {
    margin-bottom: 240px;
  }

  @include media-breakpoint-up(wide) {
    max-height: 800px;
    margin-bottom: 400px;
  }

  @include media-breakpoint-up(extra-wide) {
    max-height: 1000px;
    margin-bottom: 550px;
  }

  @include media-breakpoint-down(tablet) {
    width: 95%;
    max-height: 500px;
    margin: 0 0 350px;
    padding: 45px 30px;

    & + & {
      margin-bottom: 150px;
    }
  }

  @include media-breakpoint-down(phone) {
    max-height: 400px;
    margin: 0 0 100px;
  }
}

.feasts__title {
  margin: 0 0 60px;
}

.feasts__description {
  position: relative;
  margin-bottom: 55px;

  &::before {
    @include stretch;

    top: -30px;
    left: 0;
    width: 300px;
    height: 1px;
    background-color: $gray-400;
    content: '';
  }

  @include media-breakpoint-down(tablet) {
    &::before {
      width: 100%;
    }
  }
}

.feasts__slider {
  width: 100%;
  margin-bottom: 355px;
}
