.search-teasers {
  @extend %search-teasers;

  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.search-item {
  display: grid;
  grid-template-rows: repeat(var(--rows), minmax(min-content, max-content));
  grid-template-columns: [image-area] auto repeat(
      6,
      [order-col] minmax(80px, 6fr) [order-col-end]
    );
  column-gap: 18px;
  background: $white;

  & + & {
    margin: 20px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    grid-template-columns: [image-area] 3fr repeat(
        var(--rows),
        [order-col] minmax(50px, 1fr) [order-col-end]
      );
  }

  @include media-breakpoint-down(phone) {
    display: flex;
    flex-direction: column;
    background: $white;
  }
}

%search-item-info {
  display: flex;
  grid-column: order-col-end / order-col-end -1;
  align-items: center;
  margin-left: -18px;

  & + & {
    margin-top: 15px;
  }

  @include media-breakpoint-down(phone) {
    display: block;
    margin-left: 0;
    padding: 0 25px;
  }
}

.search-item-basket-button {
  @extend %search-item-info;

  grid-row: var(--rows);
  grid-column: order-col-end / span 2;
  margin-block: 25px 30px;

  @include media-breakpoint-down(phone) {
    width: calc(100% - 50px);
  }
}

.search-item-options {
  @extend %search-item-info;

  display: flex;
  grid-row: calc(var(--rows) - 1);
  padding: 0;
  list-style: none;

  @include media-breakpoint-down(phone) {
    padding: 0 25px;
  }
}

.search-item-option {
  // display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.search-item-option-name {
  display: block;
  margin: 0 0 5px;
}

.search-item-option-value {
  display: block;
  font-weight: bold;
}

.search-item-title {
  grid-column: order-col-end / order-col-end -1;
  margin: 0 0 20px -18px;
  padding: 30px 0 0;
  font-size: 16px;

  @include media-breakpoint-down(phone) {
    margin: 15px 0;
    padding: 0 25px;
    font-size: 15px;
  }
}

.search-item-weight {
  @extend %search-item-info;
}

.search-item-termin {
  width: 120px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @include media-breakpoint-down(phone) {
    width: auto;
    font-size: 13px;
  }
}

.search-item-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.search-item-image__holder {
  position: relative;
  grid-row: 1 / -1;
  align-self: flex-start;
  width: 275px;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  @include media-breakpoint-down(phone) {
    order: -1;
    width: 100%;

    &::before {
      padding: percentage(80 / 262) 0 0;
    }
  }
}

.search-item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-item-price {
  @extend %search-item-info;
}

.search-item-price__description {
  font-weight: bold;
}
