.delivery-custom-address-holder {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.delivery-custom-address {
  @extend %delivery-custom-address;

  display: flex;
  width: 100%;
  margin: 0 0 20px;

  > div:not(:last-of-type) {
    margin: 0 20px 0 0;
  }

  %form-row {
    width: 100px;

    %form-input {
      width: 100%;
    }
  }

  @include media-breakpoint-down(tablet) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-down(phone) {
    flex-direction: column;

    %form-row {
      width: 100%;

      > div {
        width: 100%;
      }
    }

    > div:not(:last-of-type) {
      margin: 0 0 20px;
    }
  }
}
