$searchFormSubmit-size: 50px;
$searchFormSubmit-footnote-size: calc(var(--max-width) * 0.06);

$searchFormExpanded-width: $searchFormSubmit-size;
$searchFormExpanded-footnote-width: $searchFormSubmit-footnote-size;

.search-form {
  position: relative;
  display: flex;
  width: 100%;
  background: $gray-200;

  %footnote & {
    left: 100px;
  }

  %menu & {
    display: none;

    @include media-breakpoint-down(tablet) {
      display: flex;
      padding: 0 30px;
    }
  }
}

.search-form--can-expand {
  @extend %search-form--can-expand;
}

.search-form--expanded {
  @extend %search-form--expanded;

  width: $searchFormExpanded-width;

  %footnote & {
    width: $searchFormExpanded-footnote-width;
  }
}

.search-form__input {
  flex-grow: 1;
  height: $searchFormSubmit-size;
  padding: 0 $gap;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  background: inherit;
  border: 0;
  border: 1px solid $gray-400;
  border-right: 0;
  border-radius: 0;
  transition: $UITransitionDefault;

  &:focus {
    outline: 0;
  }

  .search-form--can-expand & {
    position: absolute;
    right: 100%;
    width: 0;
    padding: 0;
    border-color: $black;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    opacity: 0;
  }

  %footnote & {
    height: $searchFormSubmit-footnote-size;
  }

  %search-form--expanded & {
    width: calc(var(--max-width) + #{$gap});
    padding: 0 $gap;
    opacity: 1;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.search-form__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $searchFormSubmit-size;
  height: $searchFormSubmit-size;
  padding: 0;
  background: none;
  border: 1px solid $gray-400;
  border-left-width: 0;
  transition: $UITransitionDefault;

  &:hover {
    @extend %search-form__button-hover;

    background: $black;
  }

  %search-form--can-expand & {
    border-left-width: 1px;
  }

  %search-form--expanded & {
    border-left-width: 0;
  }

  %footnote & {
    width: $searchFormSubmit-footnote-size;
    height: $searchFormSubmit-footnote-size;
    border-color: $black;
  }
}

$search-formButton-icon-width: 20px;
$search-formButton-icon-footnote-width: calc(var(--max-width) * 0.02);

.search-form__button-icon {
  width: $search-formButton-icon-width;
  height: $search-formButton-icon-width;
  transition: $UITransitionDefault;

  %footnote & {
    width: $search-formButton-icon-footnote-width;
    height: $search-formButton-icon-footnote-width;
  }

  %search-form__button-hover & {
    stroke: $white;
  }
}

.search-form__expand {
  %search-form--expanded & {
    display: none;
  }
}

.search-form__submit {
  %search-form--can-expand & {
    display: none;
  }

  %search-form--expanded & {
    display: flex;
  }
}
