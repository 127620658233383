.description {
  color: $gray-800;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  line-height: 1.3;

  & + & {
    margin: 20px 0;
  }

  %payment-info-block & + & {
    margin: 0;
  }

  %payment-info-block & {
    margin: 0;
  }

  %event-teaser & {
    margin: 0 0 20px;
    color: $gray-500;
  }

  @include media-breakpoint-up(wide) {
    & + & {
      margin: 55px 0;
    }
  }

  @include media-breakpoint-down(tablet) {
    font-size: 13px;
  }
}

// Color modifiers

.description--black {
  color: $black;
}

.description--white {
  color: $white;
}

// Sizes modifiers

.description--large {
  font-size: 20px;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 18px;
  }
}

.description--huge {
  font-size: 28px;
  font-size: txf-relativeValue(28px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 18px;
  }
}

// Aligns modifiers

.description--center {
  text-align: center;
}

// Child elements

.description em {
  font-weight: bold;
}
