.empty--holder {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 20px 30px;
  background: $white;

  @include media-breakpoint-up(wide) {
    display: flex;
    align-items: center;
    height: 80px;
  }

  @include media-breakpoint-up(extra-wide) {
    height: 100px;
  }
}

.empty--text {
  color: $gray-500;
  font-weight: 500;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  font-family: $fontFamily-text;

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.empty--button {
  margin: 30px 0 0;

  @include media-breakpoint-up(wide) {
    margin: 45px 0 0;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 55px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    margin: 20px 0 0;
  }
}
