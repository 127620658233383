.portal {
  z-index: 100;
  background: rgba($black, 0.5);
}

.portal__logo {
  position: fixed;
  top: 50%;
  left: calc((100% - #{var(--max-width)}) / 2);
  z-index: 10;
  width: 25%;
  transform: translate(-30%, -50%);

  @include media-breakpoint-down(tablet) {
    visibility: hidden;
  }
}

.portal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.5);
}

.portal__content {
  @include layout;

  position: relative;
  display: grid;
  grid-template-columns: repeat(10, minmax(80px, 1fr));
  min-height: 360px;
  max-height: 90vh;
  overflow: hidden;
  column-gap: 18px;
  background: $white;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.portal__inner-content {
  @extend %portal-inner-content;

  display: flex;
  flex-direction: column;
  grid-column: 4 / span 4;
  margin: auto;
  padding: 90px 0;

  @include media-breakpoint-down(tablet) {
    max-width: 70%;
    padding: 30px 25px 40px;
  }

  @include media-breakpoint-down(phone) {
    max-width: 100%;
    padding: 55px 15px 60px;
  }
}

$portal-close-size: 50px;
$portal-close-size-phone: 25px;

%portal__close-pseudo {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 30px;
  height: 2px;
  margin: -1px 0 0 -15px;
  background: $black;
  transform-origin: 50% 50%;
  transform-origin: center center;
  content: '';

  @include media-breakpoint-down(phone) {
    width: 20px;
    margin: -1px 0 0 -10px;
  }
}

.portal__close {
  @include text-replace;

  position: absolute;
  top: $portal-close-size / 2;
  right: $portal-close-size / 2;
  width: $portal-close-size;
  height: $portal-close-size;
  padding: 0;
  background: none;
  border: 0;

  &::before {
    @extend %portal__close-pseudo;

    transform: rotate(-45deg);
  }

  &::after {
    @extend %portal__close-pseudo;

    transform: rotate(45deg);
  }

  @include media-breakpoint-down(phone) {
    top: $portal-close-size-phone / 2;
    right: $portal-close-size-phone / 2;
    width: $portal-close-size-phone;
    height: $portal-close-size-phone;
  }
}
