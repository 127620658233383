.apps {
  display: grid;
  gap: 40px 10px;

  @include media-breakpoint-down(tablet) {
    display: grid;
    gap: 15px 10px;
  }
}

.apps__item {
  position: relative;
  display: block;
  width: 138px;
  width: calc(var(--max-width) * 0.14);

  @include media-breakpoint-down(tablet) {
    width: 128px;
  }
}

.apps__divisor {
  position: relative;
  display: none;
  width: 1px;
  height: 50px;
  margin: 0 20px;
  background-color: $gray-450;

  @include media-breakpoint-down(tablet) {
    %app-plate & {
      display: block;
    }
  }

  @include media-breakpoint-down(phone) {
    margin: 0 5px;
  }
}

.apps__star {
  %menu__apps & {
    display: none;
  }
}

.app {
  fill: none;
  stroke-width: 0.5;
}

.app--gray {
  @extend %app--gray;
}

.app--black {
  @extend %app--black;
}

.app--hover-black {
  @extend %app--hover-black-not-hover;

  &:hover {
    @extend %app--hover-black;

    fill: $black;
  }
}

.app--hover-white {
  &:hover {
    @extend %app--hover-white;

    fill: $white;
  }
}

.app--border-white {
  @extend %app--border-white;
}

.app--phone-black {
  @extend %app--tablet-black;

  @include media-breakpoint-down(tablet) {
    fill: $black;
  }
}

.app--phone-white {
  @extend %app--tablet-white;

  @include media-breakpoint-down(tablet) {
    fill: $white;
  }
}

.app__border {
  stroke: $white;

  %app--gray & {
    stroke: $gray-500;
  }

  %app--black & {
    stroke: $black;
  }

  %app--hover-black & {
    stroke: $black;
  }

  %app--hover-white & {
    stroke: $white;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      stroke: $black;
    }

    %app--tablet-white & {
      stroke: $white;
    }

    %app--border-white & {
      stroke: $white;
    }
  }
}

.app__logo {
  stroke: $white;

  %app--gray & {
    stroke: $gray-500;
  }

  %app--black & {
    stroke: $black;
  }

  %app--hover-black & {
    stroke: $white;
  }

  %app--hover-white & {
    stroke: $black;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      stroke: $white;
    }

    %app--tablet-white & {
      stroke: $black;
    }
  }
}

.app__logo--apple {
  stroke-width: 1px;

  %app--hover-black & {
    fill: $white;
  }

  %app--hover-white & {
    fill: $black;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      fill: $white;
    }

    %app--tablet-white & {
      fill: $black;
    }
  }
}

.app__logo--google-fill {
  %app--hover-black & {
    fill: $white;
  }

  %app--hover-white & {
    fill: $black;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      fill: $white;
    }

    %app--tablet-white & {
      fill: $black;
    }
  }
}

.app__logo--google {
  %app--hover-black & {
    stroke: $black;
  }

  %app--hover-white & {
    stroke: $white;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      stroke: $black;
    }

    %app--tablet-white & {
      stroke: $white;
    }
  }
}

.app-border--google {
  stroke: $white;

  // stylelint-disable
  %app--gray%app--hover-black-not-hover & {
    stroke: $gray;
  }

  // stylelint-enable

  %app--hover-black-not-hover & {
    stroke: $black;
  }

  %app--hover-black & {
    fill: $black;
    stroke: $black;
  }

  %app--hover-white & {
    fill: $white;
    stroke: $white;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      fill: $black;
      stroke: $black;
    }

    %app--tablet-white & {
      fill: $white;
      stroke: $white;
    }
  }
}

.app__text {
  fill: $white;

  %app--gray & {
    fill: $gray-500;
  }

  %app--black & {
    fill: $black;
  }

  %app--hover-black & {
    fill: $white;
  }

  %app--hover-white & {
    fill: $black;
  }

  @include media-breakpoint-down(tablet) {
    %app--tablet-black & {
      fill: $white;
    }

    %app--tablet-white & {
      fill: $black;
    }
  }
}
