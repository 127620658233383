.rate-form {
  display: flex;
  flex-direction: column;
}

.rates-holder {
  display: flex;

  &:hover {
    @extend %rates-holder-hover;
  }
}

.rate-label {
  box-sizing: content-box;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:not(:last-of-type) {
    padding: 0 8px 0 0;
  }

  %rates-holder-hover & {
    @extend %rate-label-holder-hover;
  }

  &--active {
    @extend %rate-label-holder-active;
  }

  &:hover ~ & {
    @extend %rate-label-hover-next;
  }

  @include media-breakpoint-down(phone) {
    width: 30px;
    height: 30px;
  }
}

.rate-icon {
  width: 100%;
  height: 100%;
  transition: fill $UITransitionDefault, stroke $UITransitionDefault;
  fill: $white;
  stroke: $gray;

  %rate-label-holder-hover & {
    stroke: $black;
  }

  %rate-label-hover-next & {
    stroke: $gray;
  }

  %rate-label-holder-active & {
    fill: $black;
    stroke: $black;
  }
}
