.star {
  display: block;
  width: 10px;
  height: 10px;
  margin-right: auto;
  margin-left: auto;
  fill: $black;

  %lead & {
    margin-top: $gap;
  }

  %ring & {
    fill: $white;
  }

  @include media-breakpoint-down(tablet) {
    %lead & {
      margin-top: 20px;
    }
  }
}
