.application {
  width: 60%;
  margin: auto;

  @include media-breakpoint-down(phone) {
    width: 100%;
  }
}

.application__phone {
  position: relative;
  width: 100%;
  height: calc(var(--max-width) * 0.51);

  @include media-breakpoint-down(phone) {
    height: 400px;
  }
}

.application__image {
  position: relative;
  z-index: 2;
  width: 50%;
  height: 50%;
  margin: auto;
  background: center no-repeat url('../../icons/phone-top.svg');
  background-size: cover;

  & + & {
    z-index: 0;
    background: center no-repeat url('../../icons/phone-bottom.svg');
    background-size: cover;
    transform: translateY(-1%);
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    background-size: 100% 100%;

    & + & {
      background-size: 100% 100%;
    }
  }
}

$application-width: 65%;
$application-phone-width: 260px;

.application__svg {
  @extend %svg-rotate;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: $application-width;
  margin: ($application-width / -2) 0 0 ($application-width / -2);
  opacity: 1;
  fill: $black-700;

  @include media-breakpoint-down(phone) {
    width: $application-phone-width;
    margin: ($application-phone-width / -2) 0 0 ($application-phone-width / -2);
  }
}

.application__apps {
  position: absolute;
  top: 42%;
  left: 50%;
  z-index: 4;
  display: grid;
  gap: 50px 10px;
  width: 25%;
  transform: translateX(-50%);

  @include media-breakpoint-down(tablet) {
    top: 50%;
    gap: 30px 10px;
    width: 110px;
    margin: -40px 0 0 -55px;
    transform: unset;
  }
}

.application__list {
  position: relative;
  margin-top: 60px;
  padding: 0;
  text-align: center;
  list-style: none;
}

.application__item {
  position: relative;
  margin-bottom: 20px;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  &:not(:last-child)::after {
    @include stretch(relative);

    left: 50%;
    display: block;
    width: 10px;
    height: 10px;
    margin-top: 20px;
    background-image: url('../../icons/star.svg');
    background-repeat: no-repeat;
    transform: translateX(-50%);
    content: '';
  }

  @include media-breakpoint-up(wide) {
    margin-bottom: 30px;

    &:not(:last-child)::after {
      width: 15px;
      height: 15px;
      margin-top: 30px;
    }
  }

  @include media-breakpoint-down(tablet) {
    margin-bottom: 15px;
    font-size: 14px;

    &:not(:last-child)::after {
      margin-top: 15px;
    }
  }
}

.application__icon {
  transition: $UITransitionDefault;

  %application__link & {
    fill: $black-700;
  }
}

.application__link {
  &:hover {
    @extend %application__link;
  }
}
