.restaurant-chef {
  width: 100%;
  max-width: 100%;
  padding: 25px 10%;
  overflow: hidden;
}

.restaurant-chef-slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  height: calc(var(--max-width) * 0.5);
  margin: 0 auto;
  padding: 50px 0;
  perspective: 1000px;
  // stylelint-disable-next-line
  -webkit-transform: translate3d(0, 0, 0);

  &-next {
    @extend %chef-slider-next;
  }

  &-prev {
    @extend %chef-slider-prev;
  }

  &-initial {
    @extend %restaurant-chef-slider-initial;
  }

  @include media-breakpoint-down(tablet) {
    width: 100%;
    height: 620px;
  }

  @include media-breakpoint-down(phone) {
    width: 75%;
    height: 450px;
  }
}

@keyframes showChefNext {
  0% {
    transform: translateX(0) scale(1) rotate(var(--rotate, 0))
      rotate3d(0, 1, 0, 0) translate3d(0, 0, 0);
  }

  50% {
    transform: translateX(100%) scale(0.7) rotate(var(--rotate, 0))
      rotate3d(0, 1, 0, -65deg) translate3d(0, 0, 0);
  }

  100% {
    transform: translateX(0) scale(1) rotate(var(--rotate, 0))
      rotate3d(0, 1, 0, 0) translate3d(0, 0, 0);
  }
}

@keyframes showChefPrev {
  0% {
    transform: translateX(0) scale(1) rotate(var(--rotate, 0))
      rotate3d(0, 1, 0, 0) translate3d(0, 0, 0);
  }

  50% {
    transform: translateX(-100%) scale(0.7) rotate(var(--rotate, 0))
      rotate3d(0, 1, 0, 65deg) translate3d(0, 0, 0);
  }

  100% {
    transform: translateX(0) scale(1) rotate(var(--rotate, 0))
      rotate3d(0, 1, 0, 0) translate3d(0, 0, 0);
  }
}

// stylelint-disable-next-line
@keyframes changeOrder {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.restaurant-chef-slide {
  $self: &;

  position: absolute;
  top: 50px;
  left: 50%;
  width: 50%;
  height: calc(100% - 100px);
  margin-left: -25%;
  transform: rotate(0);
  transition: transform $UITransitionDefault $UITransitionSlowest;

  %section-is-intersected & {
    transform: rotate(var(--rotate));
  }

  &--active {
    transform-origin: 50% 50%;
    transform-origin: center center;
    transform-style: preserve-3d;
  }

  %chef-slider-next & {
    &#{$self}--animation-next {
      animation: showChefNext 0.6s forwards;
      // , changeOrder (600ms * 0.5);
    }
  }

  %chef-slider-prev & {
    &--next#{$self}--animation-prev {
      animation: showChefPrev 0.6s forwards;
      // , changeOrder (600ms * 0.5);
    }
  }

  @include media-breakpoint-down(tablet) {
    left: 50%;
    margin-left: -25%;
  }

  @include media-breakpoint-down(phone) {
    left: calc(50% - 120px);
    width: 100%;
    margin: 0 auto;
  }
}

.restaurant-chef-slide__image-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.restaurant-chef-slide__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.restaurant-chef-slide__data {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $white;
  transform: rotate(calc(var(--rotate) * -1));

  > span {
    max-width: 70%;
    text-align: center;
  }
}

.restaurant-chef-slide__data-title {
  margin: 0 0 60px;
  color: $gray-800;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  font-family: $fontFamily-text;

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.restaurant-chef-slide__data-header {
  font-weight: bold;
  font-size: txf-relativeValue(28px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 28px;
  }
}

.restaurant-chef-slide__data-link {
  position: absolute;
  width: 100%;
  height: 100%;
  text-indent: -10000px;
}

.restaurant-chef-prev {
  @extend %restaurant-chef-prev;

  margin: 0 auto 0 10%;

  &:hover {
    @extend %restaurant-chef-prev-hover;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0 auto 0 0;
  }
}

.restaurant-chef-next {
  @extend %restaurant-chef-next;

  margin: 0 10% 0 auto;

  &:hover {
    @extend %restaurant-chef-next-hover;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0 0 0 auto;
  }

  @include media-breakpoint-down(phone) {
    transform: translateX(0);

    %restaurant-chef-prev:disabled + & {
      transform: translateX(-75%);
    }
  }
}

// stylelint-disable
.restaurant-chef-prev,
.restaurant-chef-next {
  position: relative;
  width: 13%;
  padding: 0;
  background: none;
  border: 0;
  opacity: 1;
  visibility: visible;
  transition: opacity $UITransitionDefault,
    visibility $UITransitionDefault step-start, transform $UITransitionDefault;

  &:disabled {
    opacity: 0;
    visibility: hidden;
    transition: opacity $UITransitionDefault,
      visibility $UITransitionDefault step-end, transform $UITransitionDefault;
  }

  @include media-breakpoint-down(tablet) {
    width: 100px;
  }

  @include media-breakpoint-down(phone) {
    margin: calc(100% + 300px) 0 0;
  }
}

// stylelint-enable

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.chef-slider-cirle {
  transform-origin: 50% 50%;
  transform-origin: center center;
  opacity: 0;
  transition: opacity $UITransitionDefault;
  animation: rotate 50s infinite;

  %restaurant-chef-slider-initial & {
    opacity: 1;
  }

  @include media-hover {
    %restaurant-chef-slider-initial %restaurant-chef-next-hover & {
      opacity: 0;
    }
  }
}

.restaurant-chef-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 10px auto 0;
  opacity: 0;
  transition: opacity $UITransitionDefault;

  &--visible {
    opacity: 1;
  }

  @include media-breakpoint-down(tablet) {
    opacity: 1;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    margin-top: 140px;
  }
}

.restaurant-chef-info-title {
  color: $gray-500;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  text-align: center;

  @include media-breakpoint-down(tablet) {
    font-size: 18px;
  }
}

.restaurant-chef-info-header {
  font-weight: 400;
  font-size: txf-relativeValue(62px, $unit: 'vw');
  font-family: $fontFamily-title;
  text-align: center;

  @include media-breakpoint-down(tablet) {
    font-size: 26px;
  }
}

.restaurant-chef-info-description {
  color: $gray-800;
  font-family: $fontFamily-title;
  text-align: center;
}

.restaurant-chef-icon {
  pointer-events: none;
}

.restaurant-chef-icon-arrow {
  path {
    transition: transform $UITransitionDefault;

    %restaurant-chef-prev & {
      transform: scale(-1);
      transform-origin: 50% 50%;
      transform-origin: center center;
    }

    @include media-hover {
      %restaurant-chef-next-hover & {
        transform: translateX(20px);
      }

      %restaurant-chef-prev-hover & {
        transform: scale(-1) translateX(20px);
      }
    }
  }

  line {
    opacity: 0;
    transition: opacity $UITransitionDefault, transform $UITransitionDefault;

    %restaurant-chef-prev & {
      transform: translate(10px, 0.55px);
    }

    %restaurant-chef-next & {
      transform: translateX(-20px);
    }

    @include media-hover {
      %restaurant-chef-next-hover & {
        transform: translateX(0);
        opacity: 1;
      }

      %restaurant-chef-prev-hover & {
        transform: translate(-6.5px, 0.55px);
        opacity: 1;
      }
    }
  }
}
