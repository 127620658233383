.recommended__slide {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  padding: 0 20px 40px;
  background: $white;

  @include media-breakpoint-down(phone) {
    padding: 0 25px 30px;
  }
}

.recommended__image-holder {
  position: relative;
  order: -1;
  width: calc(100% + 40px);
  margin: 0 0 0 -20px;
  overflow: hidden;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  @include media-breakpoint-down(phone) {
    width: calc(100% + 50px);
    margin: 0 0 0 -25px;

    &::before {
      padding: percentage(80 / 262) 0 0;
    }
  }
}

.recommended__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recommended__title {
  margin: 25px 0 13px;
  font-size: 16px;

  @include media-breakpoint-down(phone) {
    font-size: 15px;
  }
}

.recommended__desc {
  font-size: 14px;

  @include media-breakpoint-down(phone) {
    font-size: 13px;
  }
}

.recommended__weight {
  width: 100%;
  margin: auto 0 35px;
  color: $gray;
  font-size: 14px;

  @include media-breakpoint-down(phone) {
    margin: 17px 0 20px;
    font-size: 13px;
  }
}

.recommended__price {
  width: 50%;
  font-weight: bold;
  line-height: 40px;

  @include media-breakpoint-down(phone) {
    font-size: 15px;
    line-height: 50px;
  }
}

.recommended__add {
  height: 40px;
  margin: -40px 0 0 auto;

  @include media-breakpoint-down(phone) {
    height: 50px;
    margin: -50px 0 0 auto;
    font-size: 15px;
  }
}

.recommended__images-slider {
  @extend %recommended__images-slider;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
