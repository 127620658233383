// stylelint-disable

.basket-link__icon {
  flex-shrink: 0;
  width: 75px;
  height: 70px;
  stroke: $black;
  transition: stroke $UITransitionDefault;

  %basket-link--hover & {
    stroke: $black;
  }

  #fork {
    transform: translate(39px, 2px) rotate(45deg);
    transition: transform $UITransitionDefault;
  }

  #plate_inner {
    transition: opacity $UITransitionDefault;
    opacity: 0;
  }

  #knife {
    transition: transform $UITransitionDefault;
    transform: translate(-23px, 41px) rotate(-45deg);
  }

  @include media-breakpoint-down(tablet) {
    width: 40px;
    height: 40px;
  }
}

.basket-link__icon--contains {
  @extend %basket-link-icon-contains;
  stroke: $gray-400;

  #fork {
    transform: translate(0);
  }

  #plate_inner {
    opacity: 1;
  }

  #knife {
    transform: translate(0);
  }
}

.basket-link__count {
  font-weight: 100;
  font-size: 10px;
  font-family: $fontFamily-text;
  font-style: normal;
  line-height: 11px;
  opacity: 0;
  stroke: $black;
  transition: opacity $UITransitionDefault;

  %basket-link-icon-contains & {
    opacity: 1;
  }
}

// stylelint-enable
