.catering-order-form {
  @extend %catering-order-form;

  grid-column: 4 / span 5;
  font-size: txf-relativeValue(16px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-style: 16px;
  }
}

.catering-order__date {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  justify-content: space-between;

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;

    span {
      display: none;
    }
  }
}

.catering-order__format-other {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin: 25px 0 0;
}

.catering-order__organizer-other {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin: 25px 0 0;
}

.catering-order__fireworks-other {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin: 25px 0 0;
}

.catering-order__format-other-input {
  margin: 0 0 0 20px;
}

.catering-order__organizer-other-input {
  margin: 0 0 0 20px;
}

.catering-order__fireworks-other-input {
  margin: 0 0 0 20px;
}

.catering-order-field {
  margin-top: 30px !important;
}
