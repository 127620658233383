@keyframes font {
  from {
    font-style: normal;
    text-shadow: none;
  }

  to {
    font-style: italic;
    text-shadow: -0.5px -0.5px 0 $black-700, 0.5px -0.5px 0 $black-700,
      -0.5px 0.5px 0 $black-700, 0.5px 0.5px 0 $black-700;
  }
}

%strong {
  animation-name: font;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.lead-holder {
  &--visible strong {
    @extend %strong;
  }
}

.lead {
  @extend %lead;

  padding: 0 14% 165px;

  &::after {
    @include star;

    position: relative;
    display: block;
    margin: $gap auto 0;
    content: '';
  }

  strong {
    font-weight: 500;
  }

  @include media-breakpoint-down(tablet) {
    padding: 0;
  }
}
