.payment-method-wrapper {
  display: flex;
  align-items: baseline;
  width: 100%;
  margin: 0 0 20px;

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;
  }
}

.change-holder {
  display: flex;
  align-items: baseline;
  margin: 0 0 0 auto;

  @include media-breakpoint-down(phone) {
    flex-direction: column;
    width: 100%;
    margin: 25px 0 20px;
  }
}

.change-holder__text {
  margin: 0 18px 0 0;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 13px;
  }

  @include media-breakpoint-down(phone) {
    margin: 0;
    font-weight: 400;
    line-height: 20px;
  }
}
