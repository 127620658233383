.account-info__holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 0 80px;

  @include media-breakpoint-up(wide) {
    margin: 0 0 100px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    margin: 15px 0 0;
  }
}

.account-info-button {
  align-self: flex-end;
  margin: 0 0 0 auto;
}

.account--head {
  margin: 0;
}

.account--info {
  display: flex;
  flex-direction: column;
  margin: 20px 0 25px;

  @include media-breakpoint-up(wide) {
    margin: 30px 0 35px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 35px 0 45px;
  }

  @include media-breakpoint-down(tablet) {
    margin: 15px 0 20px;
  }
}

.account--info-wrapper {
  display: flex;
  align-items: center;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-title;

  & + & {
    margin: 15px 0 0;
  }

  @include media-breakpoint-up(wide) {
    & + & {
      margin: 20px 0 0;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    & + & {
      margin: 25px 0 0;
    }
  }

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}

.account--info-termin {
  color: $gray;
}

.account--info-description {
  padding: 0 0 0 10px;
  font-weight: bold;
  font-family: $fontFamily-title;
}
