$menuButton-size: 43px;

$menuButton-phone-size: 33px;

$menuButtonOpen-height: 22px;

$menuButtonLine-startY: ($menuButton-size - $menuButtonOpen-height) / 2;

$menuButtonLine-phone-startY: ($menuButton-phone-size - $menuButtonOpen-height) /
  2;

$menuButtonLine-tablet-height: 2px;
$menuButtonLine-height: 3px;
$menuButtonLine-wide-height: 4px;
$hamburgetLine-indent: 8px;

@function calc-line-y($number, $startY: $menuButtonLine-startY) {
  @return $startY +
    (($hamburgetLine-indent + $menuButtonLine-height) * ($number - 1));
}

@keyframes menuButtonHamburgerHide {
  from {
    background-image: linear-gradient($black, $black),
      linear-gradient($black, $black), linear-gradient($black, $black);
    background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
    background-size: 100% $menuButtonLine-height, 75% $menuButtonLine-height,
      50% $menuButtonLine-height;
  }

  to {
    background-image: linear-gradient($black, $black),
      linear-gradient($black, $black), linear-gradient($black, $black);
    background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
    background-size: 0% $menuButtonLine-height, 0% $menuButtonLine-height,
      0% $menuButtonLine-height;
  }
}

@keyframes menuButtonHamburgerShow {
  from {
    background-image: linear-gradient($black, $black),
      linear-gradient($black, $black), linear-gradient($black, $black);
    background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
    background-size: 100% $menuButtonLine-height, 75% $menuButtonLine-height,
      50% $menuButtonLine-height;
  }

  to {
    background-image: linear-gradient($black, $black),
      linear-gradient($black, $black), linear-gradient($black, $black);
    background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
    background-size: 0% $menuButtonLine-height, 0% $menuButtonLine-height,
      0% $menuButtonLine-height;
  }
}

@keyframes menuButtonCloseRotating {
  from {
    transform: rotate(0) scale(1);
  }

  to {
    transform: rotate(360) scale(1);
  }
}

@keyframes menuButtonHover {
  0% {
    background-image: linear-gradient($black, $black),
      linear-gradient($black, $black), linear-gradient($black, $black);
    background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
    background-size: 100% $menuButtonLine-height, 75% $menuButtonLine-height,
      50% $menuButtonLine-height;
  }

  50% {
    background-size: 60% $menuButtonLine-height, 100% $menuButtonLine-height,
      60% $menuButtonLine-height;
  }

  100% {
    background-image: linear-gradient($black, $black),
      linear-gradient($black, $black), linear-gradient($black, $black);
    background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
    background-size: 100% $menuButtonLine-height, 75% $menuButtonLine-height,
      50% $menuButtonLine-height;
  }
}

@keyframes menuButtonCloseAnimating {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

%menu-button-hover {
  @include media-breakpoint-up(desktop) {
    &:hover {
      transform-origin: center;
      animation-name: menuButtonHover;
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
    }
  }
}

%menu-button-close {
  transform-origin: center;
  animation-name: menuButtonHamburgerShow;
  animation-duration: 0.6s;
}

%menu-button-close-rotating {
  @include media-breakpoint-up(desktop) {
    &:hover {
      transform-origin: center;
      animation-name: menuButtonCloseRotating;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

.menu-button {
  position: relative;
  width: 4%;
  height: $menuButton-size;
  margin-top: -10px;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient($black, $black),
    linear-gradient($black, $black), linear-gradient($black, $black);
  background-repeat: no-repeat;
  background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
  background-size: 100% $menuButtonLine-height, 75% $menuButtonLine-height,
    50% $menuButtonLine-height;
  border: 0;
  cursor: pointer;
  transition: $UITransitionDefault;

  &::before {
    @include stretch;

    top: 50%;
    left: 50%;
    width: auto;
    width: 30px;
    height: 30px;
    margin: -10px 0 0 -10px;
    background-image: url('../../icons/menuCloseButton.svg');
    background-repeat: no-repeat;
    transform: scale(0);
    transform-origin: center;
    transition: $UITransitionMedium;
    content: '';
  }

  @include media-breakpoint-up(wide) {
    width: 3%;
    height: 50px;

    &::before {
      width: 35px;
      height: 35px;
      margin: -15px 0 0 -20px;
    }
  }

  @include media-breakpoint-down(tablet) {
    width: 5%;

    &::before {
      transition: $UITransitionFast;
    }
  }

  @include media-breakpoint-down(phone) {
    width: 10%;
  }
}

.menu-button--hamburger-animating {
  @extend %menu-button-close;

  animation-direction: reverse;
}

.menu-button--hamburger {
  @extend %menu-button-hover;

  background-image: linear-gradient($black, $black),
    linear-gradient($black, $black), linear-gradient($black, $black);
  background-repeat: no-repeat;
  background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
  background-size: 100% $menuButtonLine-height, 75% $menuButtonLine-height,
    50% $menuButtonLine-height;
  transition: $UITransitionDefault;
}

.menu-button--hamburger-hide {
  @extend %menu-button-close;

  position: relative;
  background-image: linear-gradient($black, $black),
    linear-gradient($black, $black), linear-gradient($black, $black);
  background-repeat: no-repeat;
  background-position-y: calc-line-y(1), calc-line-y(2.3), calc-line-y(3.6);
  background-size: 0% $menuButtonLine-height, 0% $menuButtonLine-height,
    0% $menuButtonLine-height;
  transform-origin: center;
  animation-name: menuButtonHamburgerHide;
  animation-duration: 0.6s;
}

.menu-button--hide {
  &::before {
    transform-origin: center;
    animation-name: menuButtonCloseAnimating;
    animation-duration: 0.5s;
    animation-direction: reverse;
    animation-fill-mode: backgwards;
  }
}

.menu-button--close-animating {
  &::before {
    transform-origin: center;
    animation-name: menuButtonCloseAnimating;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.menu-button--close {
  &::before {
    @extend %menu-button-close-rotating;

    transform: rotate(360deg) scale(1);

    @include media-breakpoint-down(tablet) {
      transform: scale(1);
    }
  }
}
