@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

%svg-rotate {
  animation-name: rotate;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.ring {
  @extend %ring;

  position: relative;
  padding: 100px 0 0;
  overflow: hidden;
  background: $black;

  %footer--home & {
    display: flex;
    background: none;
  }

  @include media-breakpoint-down(tablet) {
    padding: 40px 0;
  }
}

.ring__svg {
  @extend %svg-rotate;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: calc(var(--max-width) * 0.48);
  margin: 0 auto;
  animation-name: rotate;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @include media-breakpoint-down(tablet) {
    width: 280px;
    height: 280px;
  }
}

.ring__apps {
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  margin-top: 50px;
  transform: translate(-50%, -50%);

  @include media-breakpoint-down(tablet) {
    margin-top: 0;
  }
}
