.swiper-slide {
  @extend %swiper-slide;

  cursor: pointer;

  &-active {
    @extend %swiper-slide-is-active;

    cursor: auto;
  }

  &-prev {
    @extend %swiper-slide-is-prev;
  }

  &-next {
    @extend %swiper-slide-is-next;
  }
}
