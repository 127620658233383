.title {
  position: relative;
  color: $black;
  font-size: txf-relativeValue(46px, $unit: 'vw');
  font-family: $fontFamily-title;

  %portal-inner-content & {
    margin: 0 0 35px;
  }

  @include media-breakpoint-up(wide) {
    %portal-inner-content & {
      margin: 0 0 55px;
    }
  }

  @include media-breakpoint-down(tablet) {
    font-size: 32px;
  }
}

// Color modifiers

.title--white {
  color: $white;
}

.title--red {
  color: $red;
}

.title--stroke {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $black;
  transition: $UITransitionDefault;

  &:hover {
    color: $black;
  }
}

// Size's modifiers

.title--small {
  font-size: txf-relativeValue(16px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}

.title--medium {
  font-size: txf-relativeValue(20px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 18px;
  }
}

.title--large {
  font-weight: 500;
  font-size: txf-relativeValue(100px, $unit: 'vw');
  line-height: 0.7;

  %main-head & {
    line-height: 1;
  }

  %restaurant-menu & {
    line-height: 1;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 32px;
  }
}

.title--huge {
  @extend %title--huge;

  margin: 0;
  font-size: txf-relativeValue(150px, $unit: 'vw');
  line-height: 1;

  @include media-breakpoint-down(tablet) {
    font-size: 80px;
  }
}

// Style modifiers

.title--italic {
  font-style: italic;
}

// Position's modifiers

.title--center {
  text-align: center;
}

.title--right {
  text-align: right;

  // TODO: сделать независимым модификатором

  &%title--huge {
    top: 10px;
    right: -10%;
  }

  @include media-breakpoint-up(extra-wide) {
    &%title--huge {
      top: 50px;
    }
  }

  @include media-breakpoint-down(tablet) {
    &%title--huge {
      right: -30px;
    }
  }
}

// Accessibility modifiers

.title--hidden {
  @include visually-hidden;
}

// Elements

.title__link {
  color: transparent;
  text-decoration: none;
  transition: $UITransitionDefault;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $black;

  &:hover {
    color: $black;
  }

  %restaurant-teaser__title & {
    -webkit-text-stroke-width: 2px;
  }

  @include media-breakpoint-down(tablet) {
    -webkit-text-stroke-width: 0.3px;
  }
}

.title__link--reduced {
  font-size: 0.46em;
  -webkit-text-stroke-width: 0.3px;

  @include media-breakpoint-down(tablet) {
    font-size: 0.5625em;
  }
}

.title--outlined {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}
