.swiper-container {
  @extend %swiper-container;

  width: 100%;
  margin: 0;
  margin-bottom: 50px;
}

.swiper-container-vertical {
  @extend %swiper-container-vertical;

  margin-bottom: $section-gap;

  @include media-breakpoint-down(tablet) {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.swiper-container-horizontal {
  @include layout-full;
  @extend %swiper-container-horizontal;

  padding-bottom: 20px;

  &%recommended__images-slider {
    margin: 0;
    padding: 0;
  }

  &%recommended__slider {
    padding: 0 0 30px;
  }

  %stories & {
    padding: 0 0 30px;
  }

  &%team-slider {
    margin-left: calc(-1 * (100vw - var(--max-width)) / 2);
  }

  &%slider--reduced {
    box-sizing: border-box;
    width: 100vw;
    max-width: 100vw;
    padding: 0 calc((100vw - var(--max-width) * 0.8) / 2) 30px;
  }

  @include media-breakpoint-up(desktop) {
    %fiests & {
      margin-bottom: 20px;
      margin-left: calc((100vw - var(--max-width) * 0.8) / -2);
      overflow: visible;
    }
  }

  @include media-breakpoint-down(tablet) {
    overflow: hidden;

    %section & {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(phone) {
    &%recommended__images-slider {
      margin: 0;
      padding: 0;
    }

    &%recommended__slider {
      padding: 0 0 15px;
    }
  }
}

.swiper-container-title {
  @extend %swiper-container-title;

  z-index: 1;
  align-self: flex-end;
  margin-bottom: -60px;
  white-space: nowrap;

  @include media-breakpoint-down(tablet) {
    margin-bottom: -40px;
    white-space: pre-wrap;
  }
}

.swiper-container-title__doppelganger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  pointer-events: none;

  a {
    -webkit-text-stroke-color: transparent;
  }

  %swiper-container-title:hover + & > a {
    color: $black;
    -webkit-text-stroke-color: $black;
  }
}
