/* stylelint-disable rule-empty-line-before */
.sertificate__text {
  max-width: 380px;
  margin: 0;
  font-size: 1.25rem;
  font-family: $fontFamily-title;

  @include media-breakpoint-down(phone) {
    font-size: 14px;
  }
}

.sertificate__title {
  margin-bottom: 22px !important;

  @include media-breakpoint-down(phone) {
    margin-bottom: 15px !important;
    font-size: 24px;
  }
}

.sertificate__link {
  color: $black;

  &:visited {
    color: $black;
  }

  &:hover {
    text-decoration: none;
  }
}

.sertificate-links {
  &__holder {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    @include media-breakpoint-down(phone) {
      flex-direction: column;
      margin-top: 15px;
    }
  }
}

.sertificate__button {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  color: $black;
  text-decoration: none;
  border: 1px solid $black;

  & + & {
    margin-left: 20px;
  }

  &:visited {
    color: $black;
  }

  > svg {
    width: 18px;
    margin-right: 10px;
  }

  &:hover {
    color: $white;
    background: $black;

    /* stylelint-disable-next-line max-nesting-depth */
    > svg {
      /* stylelint-disable-next-line max-nesting-depth */
      * {
        fill: $white;
        stroke: $black;
      }
    }
  }

  @include media-breakpoint-down(phone) {
    width: fit-content;
    padding: 10px 20px;

    & + & {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

.sertificate__description {
  max-width: 380px;
  margin: 30px 0 0;
  font-size: 14px;
  line-height: 16px;

  %sertificate-item & {
    max-width: 100%;
    margin: 0 0 10px;
  }

  @include media-breakpoint-down(phone) {
    margin: 15px 0 0;
    font-size: 12px;
    line-height: 14px;

    %sertificate-item & {
      margin: 0 0 5px;
    }
  }
}

.sertificate__item {
  @extend %sertificate-item;

  & + & {
    margin-left: 20px;
  }

  > div {
    display: flex;

    @include media-breakpoint-down(phone) {
      flex-direction: column;
    }
  }

  @include media-breakpoint-down(phone) {
    & + & {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
