.delivery-option {
  @extend %delivery-option;

  position: relative;
  display: grid;
  grid-template-columns: repeat(10, minmax(80px, 1fr));
  align-items: baseline;
  min-height: 30px;
  column-gap: 18px;

  & ~ & {
    margin: 40px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    display: flex;
    flex-direction: column;

    & ~ & {
      margin: 25px 0 0;
    }
  }
}

.delivery-option-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-column: 3 / -1;
  align-items: center;
  justify-content: flex-start;

  %form-row + %form-checkbox {
    margin: 0 0 0 50px;
  }

  %form-checkbox ~ %form-checkbox {
    margin: 0 0 0 18px;
  }

  @include media-breakpoint-up(wide) {
    %form-row + %form-checkbox {
      margin: 0 0 0 80px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    %form-row + %form-checkbox {
      margin: 0 0 0 100px;
    }
  }

  @include media-breakpoint-down(tablet) {
    width: 100%;

    %form-row + %form-checkbox {
      min-width: 120px;
      margin: 0 0 0 18px;
    }
  }

  @include media-breakpoint-down(phone) {
    %form-row ~ %form-checkbox {
      margin: 27px 0 0;
    }

    %form-checkbox ~ %form-checkbox {
      margin: 22px 0 0;
    }
  }
}

.delivery-option-label {
  grid-column: 1 / span 2;
  font-weight: bold;
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 15px;
  }
}

.delivery-free-remains {
  margin: 0 20px 0 0;
  font-weight: bold;
  font-size: txf-relativeValue(28px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 28px;
  }

  @include media-breakpoint-down(phone) {
    margin: -29px 0 0 auto;
    font-size: 18px;
  }
}

.delivery-free-desc {
  color: $gray-800;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 13px;
  }
}
