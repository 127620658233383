.account-addresses {
  grid-column: 1 / span 5;
}

.addresses-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.account-address-form-wrapper {
  @extend %account-address-form-wrapper;

  display: flex;
  align-items: flex-end;
  margin: 20px 0 0;

  div:not(:first-child) {
    margin: 0 0 0 18px !important;
  }

  div:first-child {
    margin: 0;
  }
}
