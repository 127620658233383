.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  right: calc((100% - #{var(--max-width)}) / 2);
  z-index: 10;
  width: 20px;
  height: 20px;
  background-image: url('../../../icons/select-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;

  &::before {
    position: absolute;
    top: -150%;
    left: -150%;
    display: block;
    width: 400%;
    height: 400%;
    content: '';
  }

  @include media-breakpoint-up(wide) {
    width: 20px;
    height: 20px;
  }

  @include media-breakpoint-down(tablet) {
    left: 0;
    width: 100%;
    height: 130px;
    background: none;

    &::before {
      display: none;
    }
  }

  @include media-breakpoint-down(phone) {
    right: unset;
    left: 50%;
    margin-left: -5px;
  }
}

.swiper-button-prev {
  transform: translate(35px, -60px) rotate(180deg);

  @include media-breakpoint-up(wide) {
    transform: translate(70px, -100px) rotate(180deg);
  }

  @include media-breakpoint-up(extra-wide) {
    transform: translate(70px, -120px) rotate(180deg);
  }

  @include media-breakpoint-down(tablet) {
    top: 0;
    transform: rotate(180deg);
  }

  @include media-breakpoint-down(phone) {
    %home-restaurants-slider & {
      top: 0;
      left: 0;
      width: 100%;
      height: 80px;
      margin: 0;
    }
  }
}

.swiper-button-next {
  transform: translate(35px, 40px);

  @include media-breakpoint-up(wide) {
    transform: translate(70px, 70px);
  }

  @include media-breakpoint-up(extra-wide) {
    transform: translate(70px, 100px);
  }

  @include media-breakpoint-down(tablet) {
    top: unset;
    bottom: 0;
    transform: none;
  }

  @include media-breakpoint-down(phone) {
    %home-restaurants-slider & {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      margin: 0;
    }
  }
}
