.account {
  display: flex;
  margin-bottom: 35px;

  @include media-breakpoint-down(phone) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
}

.account--logout {
  align-self: flex-start;
  color: transparent;
  font-weight: 600;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  transition: color $UITransitionDefault;

  &:hover {
    color: $black;
  }

  @include media-breakpoint-down(tablet) {
    color: $black;
    font-size: 12px;
  }

  @include media-breakpoint-down(phone) {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 100%;
      height: 30px;
      transform: translate(-50%, -50%);
      content: '';
    }
  }
}
