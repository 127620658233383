.orders {
  display: flex;
  height: auto;
  max-height: 700px;
  margin-bottom: 145px;

  @include media-breakpoint-up(wide) {
    max-height: 1200px;
    margin-bottom: 345px;
  }

  @include media-breakpoint-down(phone) {
    flex-direction: column;
    margin-bottom: 300px;
  }
}

.orders__img {
  width: 50%;

  @include media-breakpoint-down(phone) {
    width: 100%;
  }
}

.orders__blank {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 50%;
  padding: 110px 0 110px 65px;

  @include media-breakpoint-down(phone) {
    width: 100%;
    padding: 25px 0 0 40%;
  }
}

.orders__title {
  margin: 0 0 25px;
}

.orders__description {
  margin: 0 0 40px;
}

.orders__buttons {
  display: flex;
  width: 100%;

  @include media-breakpoint-down(phone) {
    flex-direction: column;
  }
}

.orders__button {
  width: 50%;

  %portal-inner-content & {
    align-self: flex-end;
    margin: 0 0 55px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 15px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;

    & + & {
      margin: 20px 0 0;
    }

    %portal-inner-content & {
      margin: 0 0 25px;
    }
  }
}
