.delivery-button {
  display: block;
  width: 33px;
  height: 28px;
  text-indent: -9999px;
  background-image: url('../../icons/delivery.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
