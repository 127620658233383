.policy-files {
  margin: 30px 0;
}

.policy-files__item {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  width: 100%;
  padding: 25px;
  text-decoration: none;
  column-gap: 44px;
  background-color: $white;
  transition: background-color 0.2s;

  &:hover {
    @extend %policy-item-hovered;

    background-color: $gray-200;
  }

  @include media-breakpoint-down(tablet) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
  }
}

.policy-files__image-holder {
  grid-column: 2 / span 1;
  aspect-ratio: 3 / 4;

  img {
    width: 100%;
    height: 100%;
    border: 0.5px solid $gray-400;
  }
}

.policy-files__item-name {
  grid-column: 3 / span 6;
  margin: 0;
}

.policy-files__arrow {
  grid-column: -3 / -1;
  justify-self: flex-start;
  width: 62px;
  transition: width 0.2s;

  %policy-item-hovered & {
    width: 100%;
  }

  @include media-breakpoint-down(tablet) {
    align-self: flex-end;
    margin-top: 15px;
  }
}
