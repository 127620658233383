$white-100: #fff;
$white: $white-100;

$white-transparent: rgba($white, 0);

$black-700: #101820;
$black: $black-700;

$red: #fb5b47;

$error-color: $red;

$gray-200: #f7f7f7;
$gray-400: #e3e3e3;
$gray-450: #d8d8d8;
$gray-500: #b5b5b5;
$gray-800: #4f525a;
$gray-900: #3c3c3e;

$gray: $gray-500;

$orange: #dc6b2f;
