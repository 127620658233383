.swiper-pagination-custom {
  position: absolute;
  top: 50%;
  right: $layout-horizontalIndent;
  z-index: 10;
  color: transparent;
  font-size: txf-relativeValue(46px, $unit: 'vw');
  font-family: $fontFamily-title;
  white-space: nowrap;
  transform: translate(70%, -50%);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $gray-500;

  @include media-breakpoint-down(tablet) {
    display: none;
  }
}
