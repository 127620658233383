.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-top: $gap;
  opacity: 1;
  transition: $UITransitionDefault;

  %body--animated & {
    opacity: 0;
  }

  // классы добавляются через CSSTransition

  &-enter {
    display: none;
  }

  &-enter-done {
    display: flex;
  }

  &-exit-active {
    transform: translateY(100px);
    opacity: 0;
  }

  &-active-enter {
    transform: translateY(0);
    opacity: 1;
  }

  @include media-breakpoint-down(tablet) {
    padding-top: $gap-phone;
  }
}
