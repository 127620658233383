.team {
  @include media-breakpoint-down(tablet) {
    margin-bottom: 55px;
  }
}

.team__description {
  margin: 20px 120px 75px 30%;
  color: $black-700;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    margin: 0 0 50px 30vw;
    font-size: 18px;
  }
}

.team__text {
  margin: 220px 0;
  color: $black-700;
  font-size: txf-relativeValue(80px, $unit: 'vw');
  font-family: $fontFamily-title;
  font-style: italic;
  line-height: 1;

  @include media-breakpoint-down(phone) {
    margin: 100px 0;
  }
}

.team__title {
  margin-bottom: 20px;
  margin-left: 30%;
}

.team__slider {
  @extend %team-slider;
}
