.content {
  font-size: txf-relativeValue(14px, $unit: 'vw');

  h3 {
    margin-top: 40px;
    font-size: txf-relativeValue(20px, $unit: 'vw');
    font-family: $fontFamily-title;
  }

  a {
    color: $black-700;
    text-decoration: none;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;

    h3 {
      font-size: 15px;
    }
  }
}
