%home-restaurant-dimmer {
  position: absolute;
  left: calc((100% - #{var(--max-width)}) / 2);
  z-index: 2;
  display: block;
  width: 100%;
  max-width: var(--max-width);
  // height: $slider-height-desktop / 2;
  height: calc((var(--section-width) * 0.313) / 2);
  content: '';
  pointer-events: none;

  @include media-breakpoint-down(phone) {
    left: 0;
    height: 80px;
  }
}

.home-restaurants {
  position: relative;
  box-sizing: content-box;
  // height: $slider-height-desktop;
  // height: calc(var(--section-width) * 31.3%);
  padding: 130px 0;

  &::before {
    @extend %home-restaurant-dimmer;

    top: 0;
    background: linear-gradient(
      to bottom,
      $body-background,
      rgba($body-background, 0)
    );
    box-shadow: 0 -65px 25px 30px $body-background;
  }

  &::after {
    @extend %home-restaurant-dimmer;

    bottom: 0;
    background: linear-gradient(
      to top,
      $body-background,
      rgba($body-background, 0)
    );
    box-shadow: 0 65px 25px 30px $body-background;
  }

  @include media-breakpoint-down(phone) {
    width: 100vw;
    max-width: unset;
    height: 170px;
    padding: 70px 0;
  }
}

.home-title {
  margin-bottom: 50px;
}
