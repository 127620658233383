.delivery-address-button {
  margin: 0 0 0 18px;

  @include media-breakpoint-down(phone) {
    margin: 18px 0 0;
  }
}

.delivery-address-preview {
  min-width: 300px;

  %form-input {
    width: 100%;
  }
}
