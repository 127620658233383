.stories {
  @extend %stories;

  margin-bottom: 100px;

  @include media-breakpoint-up(wide) {
    margin-bottom: 130px;
  }

  @include media-breakpoint-down(tablet) {
    margin-bottom: $section-phone-gap;
  }
}
