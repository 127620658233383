.filter {
  @include layout;
  $self: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0 10px;

  &-sharing {
    margin-bottom: 60px;

    @include media-breakpoint-down(phone) {
      margin-bottom: 25px;
    }
  }

  &--sticky {
    position: sticky;
    top: $header-height;
    z-index: 10;
    background: $gray-200;
    transition: transform $UITransitionMedium;

    &#{$self}--sticked {
      transform: translateY(-100%);
    }
  }

  &--pinned {
    @extend %filter--pinned;

    background: $body-background;

    &#{$self}--sticked {
      transform: translateY(0);
    }
  }

  %main-head & {
    justify-content: flex-start;
    width: 100%;
    margin-right: 80px;
  }

  %restaurant-menu + & {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(wide) {
    padding: 0 0 20px;

    &--sticky {
      top: $header-wide-height;
    }
  }

  @include media-breakpoint-down(tablet) {
    padding: 10px 0;

    &--sticky {
      top: $header-phone-height;
      scroll-behavior: smooth;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: 100vw;
      scroll-padding: 0 30px;
      max-width: unset;
      padding: 10px 30px;
      overflow: scroll;
      scroll-snap-type: x mandatory;
      transform: none;
    }

    %main-head & {
      margin: 0;
    }

    %restaurant-menu + & {
      display: flex;
    }
  }

  @include media-breakpoint-down(phone) {
    %main-head & {
      max-width: 100%;
      margin: 0;

      > div:first-child {
        z-index: 10;
      }
    }
  }
}

$filterSelect-gap: 20px;
$filterSelect-phone-gap: 10px;

.filter__select {
  &:nth-child(1) {
    width: 45%;
  }

  &:nth-child(2) {
    width: calc(35% - #{$filterSelect-gap});
  }

  &:nth-child(3) {
    width: calc(20% - #{$filterSelect-gap});
  }

  & + & {
    margin-left: $filterSelect-gap;
  }

  @include media-breakpoint-down(tablet) {
    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      flex-grow: 0;
      width: calc(60% - #{$filterSelect-phone-gap});
      margin-left: 0;
    }

    &:nth-child(3) {
      flex-grow: 0;
      width: 40%;
      margin-left: $filterSelect-phone-gap;
    }

    & + & {
      margin-top: $filterSelect-phone-gap;
    }
  }
}

.filter__button {
  flex-shrink: 0;
  min-width: 170px;
  margin: 10px;

  @include media-breakpoint-down(phone) {
    flex-grow: 1;
    min-width: 125px;
    max-width: calc(50% - 5px);
    margin: 0 0 10px;

    &:nth-of-type(2n) {
      margin-left: 10px;
    }

    %filter--pinned & {
      max-width: unset;
      margin: 0 0 0 10px;
    }
  }
}
