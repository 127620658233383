.orders-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.order {
  display: grid;
  grid-template-rows: repeat(var(--rows), minmax(min-content, max-content));
  grid-template-columns: [image-area] auto repeat(
      6,
      [order-col] minmax(80px, 6fr) [order-col-end]
    );
  column-gap: 18px;
  background: $white;

  & + & {
    margin: 20px 0 0;
  }

  @include media-breakpoint-up(wide) {
    row-gap: 10px;
  }

  @include media-breakpoint-down(tablet) {
    grid-template-columns: [image-area] 3fr repeat(
        4,
        [order-col] minmax(50px, 1fr) [order-col-end]
      );
  }

  @include media-breakpoint-down(phone) {
    display: flex;
    flex-direction: column;
    background: $white;
  }
}

%order-item-info {
  display: flex;
  grid-column: order-col-end / order-col-end -1;
  align-items: center;
  margin: 0 0 10px -18px;

  & + & {
    margin-top: 15px;
  }

  @include media-breakpoint-down(phone) {
    display: block;
    margin-left: 0;
    padding: 0 25px;
  }
}

.order-title {
  grid-column: order-col-end / order-col-end -1;
  margin: 0 0 20px -18px;
  padding: 30px 0 0;
  font-size: txf-relativeValue(16px, $unit: 'vw');

  @include media-breakpoint-up(wide) {
    padding: 50px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }

  @include media-breakpoint-down(phone) {
    margin: 15px 0;
    padding: 0 25px;
  }
}

.order-weight {
  @extend %order-item-info;
}

.order-counter {
  @extend %order-item-info;
}

.order-termin {
  width: 25%;
  font-weight: 400;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    width: auto;
    margin-right: 5px;
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    margin: 0;
  }
}

.order-description {
  font-weight: 400;
  font-size: txf-relativeValue(15px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.order-image__holder {
  position: relative;
  grid-row: 1 / -1;
  align-self: flex-start;
  width: 275px;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  @include media-breakpoint-down(phone) {
    order: -1;
    width: 100%;

    &::before {
      padding: percentage(80 / 262) 0 0;
    }
  }
}

.order-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-price {
  @extend %order-item-info;

  padding: 0 0 30px;

  @include media-breakpoint-up(wide) {
    padding-left: 0 0 50px;
  }

  @include media-breakpoint-down(phone) {
    padding: 0 25px;
  }
}

.order-price__description {
  font-weight: bold;
}

.order__delete {
  @include text-replace;

  position: relative;
  grid-row: 1;
  grid-column: order-col-end -1;
  width: 25px;
  height: 25px;
  margin: 30px 30px 0 0;
  padding: 0;
  background: none;
  border: 0;

  &:hover {
    @extend %order-delete-hover;
  }

  @include media-breakpoint-up(wide) {
    width: 30px;
    height: 30px;
    margin: 50px 50px 0 0;
  }

  @include media-breakpoint-down(phone) {
    align-self: flex-end;
    box-sizing: content-box;
    margin: 0 20px 20px 0;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 50px;
      height: 50px;
      transform: translate(-50%, -50%);
      content: '';
    }
  }
}

.order__delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: stroke $UITransitionDefault;
  stroke: $gray;

  %order-delete-hover & {
    stroke: $black;
  }
}
