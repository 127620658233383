.delivery-day-select {
  margin: 0 18px;

  @include media-breakpoint-down(tablet) {
    margin: 17px 20px 0 0;
  }
}

.delivery-time-select {
  @include media-breakpoint-down(tablet) {
    margin: 17px 0 0;
  }
}
