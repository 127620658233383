$animationTimingFunction: cubic-bezier(0.02, 0, 0, 1);

$circle-tablet-animationDuration: 0.5s;
$circle-tablet-animationDelay: 1.2s;

$peppers-animationDelay: 0.06s;
$peppers-animationDuration: 0.667s;

$fill-animationDuration: 1.3s;
$fill-animationDelay: 0.7s;

$text-animationDuration: 0.7s;

$roundText-animationDelay: 0.23s;
$middleText-animationDelay: 0.32s;

@keyframes peper1 {
  from {
    transform: rotate(-5deg) scale(1.3) translate(50px, 0);
    opacity: 1;
  }

  to {
    transform: rotate(0deg) scale(1) translate(24px, 3px);
    opacity: 1;
  }
}

@keyframes peper2 {
  from {
    transform: rotate(-5deg) scale(1.3) translate(80px);
    opacity: 1;
  }

  to {
    transform: rotate(0deg) scale(1) translate(40px);
    opacity: 1;
  }
}

@keyframes peper3 {
  from {
    transform: rotate(-5deg) scale(1.3) translate(20px, 15px);
    opacity: 1;
  }

  to {
    transform: rotate(0deg) scale(1) translate(0, 15px);
    opacity: 1;
  }
}

@keyframes peper4 {
  from {
    transform: rotate(-5deg) scale(1.3) translate(40px, 31px);
    opacity: 1;
  }

  to {
    transform: rotate(0deg) scale(1) translate(10px, 31px);
    opacity: 1;
  }
}

@keyframes peper5 {
  from {
    transform: rotate(-5deg) scale(1.3) translate(30px);
    opacity: 1;
  }

  to {
    transform: rotate(0deg) scale(1) translate(5px);
    opacity: 1;
  }
}

@keyframes text-round {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fill {
  from {
    transform: scale(1) translate(-10px, -3px);
  }

  to {
    transform: scale(10) translate(-10px, -15px);
  }
}

$circle-phone-width: 30vw;

@keyframes circle {
  from {
    left: calc(50% - #{$circle-phone-width} / 2);
  }

  to {
    left: 2px;
  }
}

%peppers {
  transform-origin: center;
  opacity: 0;
  animation-duration: $peppers-animationDuration;
  animation-timing-function: $animationTimingFunction;
  animation-fill-mode: forwards;
}

%text {
  transform-origin: center;
  animation-name: text-round;
  animation-duration: $text-animationDuration;
  animation-timing-function: $animationTimingFunction;
  animation-fill-mode: forwards;
}

%fill {
  transform-origin: center;
  animation-name: fill;
  animation-duration: $fill-animationDuration;
  animation-timing-function: $animationTimingFunction;
  animation-fill-mode: forwards;
}

%circle-is-animating {
  @include media-breakpoint-down(tablet) {
    animation-name: circle;
    animation-duration: $circle-tablet-animationDuration;
    animation-timing-function: $animationTimingFunction;
    animation-delay: $circle-tablet-animationDelay;
    animation-fill-mode: forwards;
  }
}

$circle-width: 275px;

.circle {
  @extend %circle;

  position: fixed;
  top: 30%;
  left: 2%;
  z-index: -10;
  width: 25%;
  opacity: 1;
  transition: opacity $UITransitionDefault;
  pointer-events: none;

  %body--animated & {
    @extend %circle-is-animating;
  }

  &-is-hidden {
    opacity: 0;
  }

  @include media-breakpoint-up(wide) {
    top: 25%;
  }

  @include media-breakpoint-down(tablet) {
    left: 2px;
    width: $circle-phone-width;
    margin-left: 0;

    %body--animated & {
      left: calc(50% - #{$circle-phone-width} / 2);
    }
  }

  @include media-breakpoint-down(phone) {
    top: 330px;
  }
}

.circle__rotate {
  transform-origin: 44% 48%;
  will-change: transform;

  %menu-is-animating ~ %circle & {
    transition: $UITransitionDefault;
  }
}

.circle__fill {
  @extend %fill;

  opacity: 1;
  animation-delay: $fill-animationDelay;
}

.circle__pepper1 {
  @extend %peppers;

  animation-name: peper1;
}

.circle__pepper2 {
  @extend %peppers;

  animation-name: peper2;
  animation-delay: $peppers-animationDelay;
}

.circle__pepper3 {
  @extend %peppers;

  animation-name: peper3;
  animation-delay: $peppers-animationDelay * 2;
}

.circle__pepper4 {
  @extend %peppers;

  animation-name: peper4;
  animation-delay: $peppers-animationDelay * 3;
}

.circle__pepper5 {
  @extend %peppers;

  animation-name: peper5;
  animation-delay: $peppers-animationDelay * 4;
}

.circle__text-holder {
  @extend %text;

  opacity: 0;
  transition: fill $UITransitionDefault;
  animation-delay: $roundText-animationDelay;
}

.circle__middle-text {
  @extend %text;

  opacity: 0;
  animation-delay: $middleText-animationDelay;
}
