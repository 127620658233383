.catering-head {
  margin-bottom: 90px;
  padding: 0 17% 0 24%;

  @include media-breakpoint-down(tablet) {
    margin-bottom: 40px;
    padding: 0 0 0 30vw;
  }
}

.catering-head__title {
  margin: 30px 0 40px;
  font-weight: bold;
  font-size: txf-relativeValue(100px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    margin: 20px 0;
    font-size: 32px;
  }
}
