.delivery {
  margin: 0 0 80px;

  @include media-breakpoint-down(phone) {
    > h2 {
      font-size: 18px;
    }
  }
}

.delivery__title {
  margin: 0 0 20px;
}
