.app-plate {
  @extend %app-plate;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 17px 25px 17px 100px;
  background: $white;

  @include media-breakpoint-down(tablet) {
    flex-direction: column-reverse;
    padding-left: 25px;
  }
}

.app-plate__apps {
  display: flex;
}

.app-pate__text {
  padding: 0 80px 0 100px;

  @include media-breakpoint-down(tablet) {
    margin: 20px 0;
    padding: 0;
    text-align: center;
  }
}

%app-plate-close-pseudo {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 30px;
  height: 2px;
  margin: -1px 0 0 -15px;
  background: $black;
  transform-origin: 50% 50%;
  transform-origin: center center;
  content: '';
}

.app-plate__close {
  @include text-replace;

  position: relative;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  background: none;
  border: 0;

  &::before {
    @extend %app-plate-close-pseudo;

    transform: rotate(-45deg);
  }

  &::after {
    @extend %app-plate-close-pseudo;

    transform: rotate(45deg);
  }

  @include media-breakpoint-down(tablet) {
    align-self: flex-end;
  }
}
