.slide {
  position: relative;
  // height: 430px;
  // padding: 115px 370px 70px 100px;
  color: $white;

  &::before {
    @include stretch;

    z-index: -1;
    background: $black;
    opacity: 0.5;
    transition: opacity $UITransitionDefault;
    content: '';
    pointer-events: none;
  }

  // stylelint-disable
  %home-slider-preview & {
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
  // stylelint-enable

  @include media-breakpoint-down(tablet) {
    padding: 130px 30px 60px 25px;
  }

  @include media-breakpoint-down(phone) {
    padding: 0;
  }
}

.slide--gallery {
  @extend %slide--gallery;

  height: auto;
  padding: 0;
}

.slide__title {
  margin: 0 0 10px;
  transform: translate(-100px);
  opacity: 0;
  transition: transform 1s 0.6s, opacity 1s 0.6s;

  %swiper-slide & {
    opacity: 0;
  }

  %swiper-slide-is-prev + %swiper-slide & {
    transition: transform 1s 0.1s, opacity 1s 0.1s;
  }

  %swiper-slide-is-next & {
    transition: transform 1s 0.1s, opacity 1s 0.1s;
  }

  %slider-observable-finished %swiper-slide & {
    transition: transform 1s 0.1s, opacity 1s 0.1s;
  }

  %swiper-slide-is-active & {
    transform: translate(0);
    opacity: 1;
  }
}

.slide__description {
  margin: 0 0 80px;
  font-weight: 300;
  transform: translate(-80px);
  opacity: 0;
  transition: transform 0.8s 0.6s, opacity 0.8s 0.6s;

  %swiper-slide & {
    opacity: 0;
  }

  %swiper-slide-is-prev + %swiper-slide & {
    transition: transform 0.8s 0.1s, opacity 0.8s 0.1s;
  }

  %swiper-slide-is-next & {
    transition: transform 0.8s 0.1s, opacity 0.8s 0.1s;
  }

  %slider-observable-finished %swiper-slide & {
    transition: transform 0.8s 0.1s, opacity 0.8s 0.1s;
  }

  %swiper-slide-is-active & {
    transform: translate(0);
    opacity: 1;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0 0 60px;
  }
}

.slide__image {
  @include stretch;

  z-index: -2;
  object-fit: cover;

  %slide--gallery & {
    position: static;
  }

  &.mobile {
    display: none;

    @include media-breakpoint-down(phone) {
      display: block;
    }
  }

  @include media-breakpoint-down(phone) {
    display: none;
  }
}

.slide__more {
  color: $white;

  &::before {
    z-index: 10;
    @include stretch;

    content: '';
  }

  %swiper-slide & {
    pointer-events: none;
  }

  %swiper-slide-is-active & {
    pointer-events: all;
  }
}
