.collaboration {
  display: flex;
  flex-direction: column;
}

.collaboration__list {
  margin: 65px 0 160px;
  padding-left: 30%;
  list-style: none;

  @include media-breakpoint-up(wide) {
    margin-bottom: 250px;
  }

  @include media-breakpoint-down(phone) {
    margin: 0 0 260px;
    padding-left: 30vw;
  }
}

.collaboration__item {
  &:not(:last-child) {
    margin-bottom: 110px;
  }

  @include media-breakpoint-down(phone) {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }
}

.collaboration__count {
  margin: 0;
  font-size: txf-relativeValue(150px, $unit: 'vw');
  font-family: $fontFamily-title;
  line-height: 1.2;

  @include media-breakpoint-down(phone) {
    height: 40px;
    font-size: 40px;
  }
}

.collaboration__text {
  margin: -40px 0 0 5px;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-up(wide) {
    margin: -50px 0 0 5px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: -70px 0 0 5px;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0 0 5px;
    font-size: 20px;
  }
}

.collaboration__offer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 80%;
  padding: 60px 100px;
  background: $white;
  transform: translateY(60px);
  opacity: 0;
  transition: transform $UITransitionSlowest, opacity $UITransitionSlow;

  &--intersected {
    transform: translateY(0);
    opacity: 1;
  }

  &:nth-child(odd) {
    @extend %collaboration__offer-odd;

    align-self: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 150px;
  }

  @include media-breakpoint-up(wide) {
    &:not(:last-child) {
      margin-bottom: 200px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    &:not(:last-child) {
      margin-bottom: 240px;
    }
  }

  @include media-breakpoint-down(tablet) {
    padding: 60px 50px 60px 100px;
  }

  @include media-breakpoint-down(phone) {
    align-self: flex-start;
    width: calc(100% + 30px);
    padding: 220px 30px 55px;

    &:nth-child(odd) {
      align-self: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: 260px;
    }
  }
}

.collaboration__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  width: 55%;
  text-align: left;

  %collaboration__offer-odd & {
    align-items: flex-start;
    align-self: flex-start;
    text-align: right;
  }

  @include media-breakpoint-down(phone) {
    align-self: flex-start;
    width: 90%;
    text-align: left;

    %collaboration__offer-odd & {
      text-align: left;
    }
  }
}

.collaboration__presentation {
  margin-bottom: 25px;
  color: $gray-500;
  font-weight: bold;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-title;
  font-style: italic;

  @include media-breakpoint-down(phone) {
    color: $black-700;
    font-size: 16px;
  }
}

.collaboration__title {
  margin: 0 0 30px;
}

.collaboration__description {
  margin-bottom: 60px;

  @include media-breakpoint-up(wide) {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin-bottom: 100px;
  }
}

.collaboration__button {
  align-self: flex-start;
  width: 50%;

  %collaboration__offer-odd & {
    align-self: flex-end;
  }

  @include media-breakpoint-down(tablet) {
    width: 100%;
  }

  @include media-breakpoint-down(phone) {
    align-self: center;

    %collaboration__offer-odd & {
      align-self: center;
    }
  }
}

.collaboration__image-holder {
  position: absolute;
  top: -50px;
  left: -25%;
  width: 60%;
  height: 100%;

  %collaboration__offer-odd & {
    right: -25%;
    left: auto;
  }

  @include media-breakpoint-down(phone) {
    top: -200px;
    left: -30px;
    width: 90%;
    height: 60%;

    %collaboration__offer-odd & {
      top: -200px;
      left: -30px;
    }
  }
}

.collaboration__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
