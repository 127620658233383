$gap: 30px;
$gap-phone: 15px;

$header-height: 80px;
$header-phone-height: 50px;
$header-wide-height: 100px;

$layout-width: 960px;

$layout-width-wide: 1100px;

$layout-width-extra-wide: 1500px;

$layout-horizontalIndent: calc((100% - var(--max-width)) / 2);
$layout-tablet-horizontalIndent: 70px;
$layout-phone-horizontalIndent: 30px;

$section-gap: calc(var(--max-width) * 0.2);

$section-phone-gap: 40px;
