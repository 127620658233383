// .search-teasers {
//   display: grid;
//   grid-template-columns: 1fr;
//   gap: 15px;

//   @include media-breakpoint-down(tablet) {
//     gap: 30px;
//   }
// }

.search-teaser {
  position: relative;
  padding: 35px 90px 35px 35px;
  transition: $UITransitionDefault;

  &:hover {
    @extend %search-teaser-hover;

    background: $white;
  }

  @include media-breakpoint-down(tablet) {
    padding: 0;

    &:hover {
      background: none;
    }
  }
}

.search-teaser__title {
  margin: 0 0 10px;
  font-weight: normal;
}

.search-teaser__description {
  transition: $UITransitionDefault;

  %search-teaser-hover & {
    color: $gray-500;
  }

  %search-teaser-hover & strong {
    color: $gray-800;
  }
}

.search-teaser__link {
  @include stretch;
}
