.info {
  position: relative;
  z-index: 10;
  width: 80%;
  padding: 50px 40% 85px 100px;
  background: $white;

  p:first-of-type {
    margin: 45px 0 0;
  }

  p:not(:first-of-type) {
    margin: 20px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    width: calc(100% + #{$gap});
    margin-left: $gap * -1;
    padding: 40px 30px 50px 60px;
    font-size: 13px;

    p:first-of-type {
      margin: 20px 0 0;
    }

    p:not(:first-of-type) {
      margin: 15px 0 0;
    }
  }
}

.info__title {
  margin: 0 0 45px;
  padding-bottom: 45px;
  border-bottom: 1px solid $gray-400;

  @include media-breakpoint-down(tablet) {
    margin: 0 0 20px;
    padding-bottom: 20px;
  }
}

.info__button {
  margin-top: 90px;

  @include media-breakpoint-down(tablet) {
    width: 100%;
    margin-top: 20px;
  }
}

.info__map-holder {
  @extend %info-map-holder;

  position: absolute;
  top: 20%;
  right: -25%;
  width: calc(var(--max-width) * 0.5);
  height: 80%;
  transition: transform $UITransitionDefault;

  @include media-breakpoint-down(tablet) {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    height: 265px;
    margin-top: 25px;
  }
}

.info__map {
  // position: absolute;
  width: 100%;
  height: 100%;
}
