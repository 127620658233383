.event-teasers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @include media-breakpoint-down(tablet) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

$event-teaser-padding: 150px;

.event-teaser {
  @extend %event-teaser;

  position: relative;
  height: 100%;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  line-height: 1.15;
  background: $white;

  &:hover {
    @extend %event-teaser-hover;
  }

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 136% 0 0;
    content: '';
  }

  %swiper-slide & {
    // stylelint-disable-next-line
    &::before {
      padding: 50% 0 0;
    }
  }

  %event-teaser__slide &::before {
    display: none;
  }

  &--large {
    @extend %event-teaser--large;
  }

  @include media-breakpoint-down(tablet) {
    grid-template-columns: 1fr;
    font-size: 13px;

    &::before {
      display: none;
    }

    &--large {
      height: auto;
      padding: 65px 30px 40px;
    }
  }

  @include media-breakpoint-down(phone) {
    overflow: hidden;
  }
}

.event-teaser-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 35px 20%;

  %swiper-slide & {
    padding: 80px 17%;
  }

  %event-teaser__slide & {
    position: relative;
  }

  %event-teaser--large & {
    padding-left: calc(50% + 100px);
  }

  @include media-breakpoint-up(wide) {
    padding: 80px 20%;
  }

  @include media-breakpoint-up(extra-wide) {
    padding: 100px 20%;
  }

  @include media-breakpoint-down(tablet) {
    position: relative;
    grid-template-columns: 1fr;
    padding: 30px;
    font-size: 13px;

    %event-teaser--large & {
      padding: 65px 30px 40px;
    }
  }

  @include media-breakpoint-down(phone) {
    padding: 35px 10%;
  }
}

.event-teaser__logo {
  width: 75px;
  height: 75px;
  margin-bottom: 15px;
  object-fit: contain;

  @include media-breakpoint-up(wide) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(tablet) {
    width: 60px;
    height: 60px;
    margin-bottom: 30px;
  }
}

.event-teaser__image-holder {
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  %event-teaser--large & {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }

  @include media-breakpoint-down(tablet) {
    %event-teaser--large & {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
}

$grid-gap: 20px;

.event-teaser__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-teaser__title {
  margin: 0 0 15px;
  font-weight: bold;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 15px;
  }
}

.event-teaser__description {
  margin: 0 0 20px;
  color: $gray-500;
  transition: $UITransitionDefault;

  %event-teaser-hover & {
    color: $black;
  }
}

.event-teaser__footer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: auto;
}

.event-teaser__date {
  display: block;
  order: -1;
  width: 100%;
  margin-right: $gap;
  margin-bottom: 10px;

  @include media-breakpoint-down(tablet) {
    width: auto;
    margin-bottom: 0;

    %event-teaser__price + & {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.event-teaser__price {
  @extend %event-teaser__price;

  margin-right: $gap;

  @include media-breakpoint-down(phone) {
    width: 50%;
    margin-top: 20px;
  }
}

.event-teaser__more {
  &::before {
    @include stretch;

    content: '';
  }

  @include media-breakpoint-down(tablet) {
    margin-left: auto;

    %event-teaser__price + & {
      margin-left: 0;
    }
  }

  @include media-breakpoint-down(phone) {
    %event-teaser__price + & {
      margin-top: auto;
    }
  }
}

.event-teaser__slide {
  @extend %event-teaser__slide;

  position: relative;
  height: auto;
}

.event-teaser__author {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}
