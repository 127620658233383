.dish-modal-property-checkbox {
  margin: 0 0 20px;
}

.modal-sharing {
  width: 100%;
  max-height: 360px;
  overflow: hidden;

  &__portal {
    max-height: 360px;
    overflow: hidden;
  }

  h2 {
    font-weight: bold;
    font-size: 46px;
    font-family: $fontFamily-title;
    line-height: 56px;

    @include media-breakpoint-down(phone) {
      font-size: 32px;
      line-height: 44px;
    }
  }
}

.modal-sharing__container {
  display: flex;
}

.modal-sharing__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid $black !important;

  &:first-child {
    margin-right: 20px;
  }
}

.modal-sharing__ok {
  width: 20px;
  height: 33px;
}

.modal-sharing__vk {
  width: 34px;
  height: 19px;
}
