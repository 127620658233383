.address-item {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  background: $white;

  & + & {
    margin: 8px 0 0;
  }

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    padding: 17px 25px 20px;
  }
}

.address-item__title {
  flex-shrink: 0;
  max-width: 15%;
  margin: 0 10px 0 0;
  overflow: hidden;
  color: $gray;
  font-weight: bold;
  font-family: $fontFamily-title;
  line-height: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include media-breakpoint-down(phone) {
    width: 100%;
    max-width: 100%;
    margin: 0 0 9px;
    line-height: unset;
  }
}

.address-item__address {
  padding: 0 30px 0 0;
  overflow: hidden;
  font-weight: 500;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  line-height: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    line-height: unset;
  }
}

.address-item__change {
  margin: 0 0 0 auto;
  padding: 0;
  font-family: $fontFamily-title;
  line-height: 60px;
  background: none;
  border: 0;

  @include media-breakpoint-down(phone) {
    margin: 0;
    line-height: unset;
  }
}

.address-item__delete {
  @include text-replace;

  position: relative;
  flex-shrink: 0;
  width: 50px;
  margin: 0 0 0 10px;
  padding: 0;
  overflow: hidden;
  line-height: 60px;
  background: none;
  border: 0;

  &:hover {
    @extend %address-item-delete-hover;
  }

  @include media-breakpoint-down(phone) {
    width: 20px;
    height: 20px;
    line-height: unset;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 35px;
      height: 35px;
      transform: translate(-50%, -50%);
      content: '';
    }
  }
}

$address-item-delete-icon-size: 20px;

.address-item__delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $address-item-delete-icon-size;
  height: $address-item-delete-icon-size;
  margin: ($address-item-delete-icon-size / -2) 0 0
    ($address-item-delete-icon-size / -2);
  transition: $UITransitionDefault;
  pointer-events: none;
  stroke: $gray;

  %address-item-delete-hover & {
    stroke: $black;
  }
}
