.form {
  @extend %form;

  display: flex;
  flex-direction: column;
}

.form__row {
  @extend %form-row;

  position: relative;
  display: flex;
  width: 100%;
  margin: 0 0 40px;

  &--error {
    @extend %form__row--error;
  }

  &--column {
    @extend %form-row-column;

    flex-direction: column;
  }

  &--row {
    align-items: flex-start;
  }

  %account--form &,
  %portal-inner-content & {
    margin: 0 0 20px;
  }

  %auth-form &,
  %portal-inner-content & {
    margin: 0 0 20px;
  }

  &--marginless {
    margin: 0;

    %portal-inner-content & {
      margin-bottom: 0;
    }
  }

  &--fullWidth {
    @extend %form-row--fullwidth;

    width: 100%;
  }

  %delivery-option & {
    width: 30%;

    // stylelint-disable
    &%form-row--fullwidth {
      width: 100%;
    }
    // stylelint-enable
  }

  %vacancies-form &,
  %feedback-form & {
    align-items: center;
  }

  @include media-breakpoint-up(wide) {
    %account--form &,
    %portal-inner-content & {
      margin: 0 0 70px;
    }
  }

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;

    %delivery-option & {
      width: 100%;
    }

    %portal-inner-content & {
      margin: 0 0 30px;
    }
  }
}

.form__item--raw {
  label {
    color: $gray-800;
    font-size: txf-relativeValue(12px, $unit: 'vw');
    line-height: 20px;
  }

  @include media-breakpoint-down(tablet) {
    label {
      font-size: 12px;
    }
  }
}

.form__row--content-column {
  @extend %form-row-content-column;

  display: flex;
  flex-direction: column;
  width: 100%;
}

$formLabel-marginRight: 20px;

.form__label {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 25%;
  margin-right: $formLabel-marginRight;
  font-weight: bold;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-title;
  cursor: pointer;

  %form__row--error & {
    color: $error-color;

    %promo-form & {
      color: $black;
    }
  }

  %catering-order-form & {
    width: 30%;
  }

  %form-row-column & {
    margin: 0 0 7px;
  }

  @include media-breakpoint-up(wide) {
    height: 70px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 15px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.form__input {
  @extend %form-input;

  width: 75%;
  height: 50px;
  padding: 10px 5%;
  color: $black;
  font-weight: 500;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  font-family: $fontFamily-text;
  border-style: none;
  transition: color $UITransitionDefault;

  %portal-inner-content & {
    width: 100%;
    border: 1px solid $gray-400;
  }

  %form__row--error & {
    border: 1px solid $error-color;
  }

  %auth-form__code-holder & {
    margin: 0;
    padding: 0 1em;
    text-align: center;
  }

  %delivery-option & {
    width: auto;

    @include media-breakpoint-down(tablet) {
      width: 100%;
    }
  }

  //stylelint disable
  %delivery-option %form-row-fullwidth & {
    width: 100%;
  }
  //stylelint enable

  %catering-order-form & {
    width: 70%;
  }

  &--untouched {
    color: $gray;
  }

  @include media-breakpoint-up(wide) {
    height: 70px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;

    %delivery-option & {
      width: 100%;
    }
  }
}

.form__agreements {
  display: flex;
  width: 75%;
  margin: 0 0 0 auto;
  padding: 0 0 0 20px;
  font-size: 12px;

  %catering-order-form & {
    width: 70%;
  }

  @include media-breakpoint-down(phone) {
    padding: 0;

    %feedback-form &,
    %vacancies-form & {
      margin: 0;
    }
  }
}

.form__checkbox {
  @include visually-hidden;

  &:checked {
    @extend %form__checkbox-checked;
  }
}

.form__button {
  align-self: flex-end;

  @include media-breakpoint-down(phone) {
    align-self: auto;
  }
}

.form__agreement {
  @extend %form__agreement;

  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &::before {
    @include stretch(relative);

    display: block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background: $white;
    border: 1px solid $black-700;
    content: '';
  }

  &::after {
    @include stretch;

    top: 15%;
    left: 1px;
    display: block;
    background-image: url('../../../icons/agreement-icon.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 0;
    content: '';
  }
}

%form__checkbox-checked + %form__agreement {
  &::after {
    opacity: 1;
  }
}

%form-icon {
  display: block;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  padding: 5px;
  background: $white;
  border-radius: 15px;
  cursor: pointer;
  transition: $UITransitionDefault;

  %form__upload-hover & {
    background: $black-700;
  }

  @include media-breakpoint-up(wide) {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    padding: 10px;
    border-radius: 25px;
  }

  @include media-breakpoint-down(phone) {
    padding: 5px;
    border-radius: 25px;
  }
}

.form__circle {
  @extend %form-icon;
}

.form__clear {
  @extend %form-icon;

  padding: 7px;

  &:hover {
    @extend %form__clear-hover;

    background-color: $black-700;
  }
}

.form__cross {
  transition: $UITransitionDefault;
  fill: $black-700;

  %form__clear-hover & {
    fill: $white;
  }
}

.form__clip {
  transition: $UITransitionDefault;
  fill: $gray-900;

  %form__upload-hover & {
    fill: $white;
  }
}

.form__upload {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: calc(25% + 15px);
  color: $gray-500;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  cursor: pointer;
  transition: $UITransitionDefault;

  %catering-order-form & {
    margin-left: calc(30% + 15px);
  }

  &:hover {
    @extend %form__upload-hover;

    color: $black-700;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }

  @include media-breakpoint-down(phone) {
    justify-content: flex-end;
    width: 100%;
    margin: 0;

    %vacancies-form & {
      justify-content: flex-start;
    }
  }
}

.form__file {
  @include visually-hidden;
}

.form__error-text {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  color: $error-color;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  line-height: 20px;

  %form & {
    right: 0;
    left: unset;
    width: 75%;
    padding: 0 0 0 20px;
  }

  %form-checkbox-error & {
    width: calc(75% - 20px);
    padding: 0 0 0 30px;
    font-weight: normal;
  }

  %catering-order-form & {
    width: 70%;
  }

  @include media-breakpoint-up(extra-wide) {
    line-height: 30px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 12px;
  }
}
