.history__more-holder {
  position: relative;
  display: flex;
  margin: 30px 0 0;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-up(wide) {
    margin: 50px 0 0;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 80px 0 0;
  }

  @include media-breakpoint-down(tablet) {
    margin: 20px 0 0;
    font-size: 14px;
  }
}

.history__more {
  margin-left: 16px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }
}
