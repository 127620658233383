.dish-basket-holder {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  button + button {
    margin: 0 0 0 18px;
  }

  @include media-breakpoint-down(phone) {
    flex-direction: column;

    button {
      width: 100%;
    }

    button + button {
      margin: 18px 0 0;
    }

    .button + .button {
      margin-left: 0;
    }
  }
}

.dish-basket-description {
  margin-bottom: 30px;

  @include media-breakpoint-up(wide) {
    margin-bottom: 50px;
  }
}

.dish-basket-title {
  font-size: txf-relativeValue(30px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 26px;
  }
}

.dish-property-select {
  margin: 20px 0;
}
