.profile-teaser {
  display: flex;
  align-items: center;
  min-width: 370px;
  margin-bottom: 20px;
  padding: 20px 0;
  // margin-left:
  color: $black;
  border-bottom: 0.85px solid $gray-400;

  @include media-breakpoint-down(tablet) {
    width: 100%;
    min-width: unset;
    padding: 10px 0;
  }
}

$profile-teaser-photo-size: calc(var(--max-width) * 0.04);

.profile-teaser__photo {
  width: $profile-teaser-photo-size;
  height: $profile-teaser-photo-size;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 50%;

  @include media-breakpoint-down(tablet) {
    width: 40px;
    height: 40px;
  }
}

.profile-teaser__greeting {
  padding: 0;
  color: currentColor;
  font-size: 14px;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  text-decoration: none;

  &::after {
    display: block;
    height: 0;
    overflow: hidden;
    font-weight: bold;
    visibility: hidden;
    content: attr(data-text) / '';
    user-select: none;
    pointer-events: none;
  }

  &:hover {
    font-weight: bold;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.profile-teaser__login {
  padding: 0;
  color: currentColor;
  font-weight: 500;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  background: none;
  border: 0;

  &:hover {
    font-weight: bold;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.profile-teaser__logout {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0;
  color: transparent;
  font-weight: 500;
  font-size: 12px;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  background: none;
  border: 0;
  transition: $UITransitionDefault;

  &:hover {
    @extend %profile-teaser__logout;

    color: $black;
  }

  @include media-breakpoint-down(tablet) {
    color: $black;
    font-size: 12px;
  }
}

$profileTeaserLogoutIcon-width: 30px;

.profile-teaser__logout-icon {
  flex-shrink: 0;
  width: calc(var(--max-width) * 0.03);
  margin-left: $gap / 2;
  transition: $UITransitionDefault;

  %profile-teaser__logout & {
    stroke: $black;
  }

  %button--text-hover & {
    stroke: $black;
  }

  @include media-breakpoint-down(tablet) {
    width: $profileTeaserLogoutIcon-width;
  }
}
