.socials {
  display: flex;
  justify-content: center;
  padding: 0;
  color: $gray-500;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  list-style: none;

  &[data-label]::before {
    margin-right: 40px;
    color: $black;
    content: attr(data-label);
  }

  %footnote & {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    width: 40vh;
    height: 100%;
    margin: 0;
    transform: translate(calc((75% + 40px) * -1));
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;
    font-size: 13px;
    text-align: center;

    &[data-label]::before {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

.socials__item {
  pointer-events: all;

  & + & {
    margin: 0 0 0 10%;
  }
}

.socials__link {
  display: block;
  height: 100%;
  color: $gray;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  text-decoration: none;
  transition: color $UITransitionDefault;

  &:hover {
    color: $black;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}
