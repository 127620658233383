.slider {
  &--observable {
    @extend %slider-is-observable;

    transform: translate(99%);
    opacity: 0;
    transition: opacity $UITransitionSlowest,
      transform 1s cubic-bezier(0.55, 1.03, 0.87, 0.97);
  }

  &--observable-finished {
    @extend %slider-observable-finished;
  }

  &--shift {
    margin: 0 0 0 (calc(((100vw - var(--max-width)) / 2) * -1));
  }

  &--reduced {
    @extend %slider--reduced;
  }

  &--visible {
    transform: translate(0);
    opacity: 1;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0;
  }
}

.slider--gap-section {
  margin-bottom: $section-gap;

  &%home-slider-preview {
    margin-bottom: 165px;
  }

  @include media-breakpoint-down(phone) {
    margin-bottom: $section-phone-gap;

    &%home-slider-preview {
      margin-bottom: $section-phone-gap;
    }
  }
}

.slider--gapless {
  margin-bottom: 0;
}

.slider--scrollbar-shrinked {
  %swiper-scrollbar {
    width: calc((100% - (100vw - var(--max-width))));
  }

  @include media-breakpoint-down(phone) {
    %swiper-scrollbar {
      width: calc(100% - 60px);
    }
  }
}

.slider--scrollbar-reduced {
  width: calc((var(--max-width) * 0.8));

  > %swiper-scrollbar {
    margin: 0 0 0 calc((100vw - var(--max-width) * 0.8) / 2);
  }
}

.slider--scrollbar-shift {
  > %swiper-scrollbar {
    margin: 0 0 0 calc((100vw - var(--max-width)) / 2);
  }

  @include media-breakpoint-down(phone) {
    > %swiper-scrollbar {
      margin: 0 0 0 30px;
    }
  }
}
