.vacancies {
  @include media-breakpoint-down(tablet) {
    margin-bottom: 55px;
  }
}

.vacancies__list {
  width: 100%;
  margin: 0;
  padding-left: 22%;
  overflow: hidden;

  @include media-breakpoint-down(tablet) {
    padding-left: 0;
  }
}

.vacancies__description {
  margin: 20px 120px 75px 30%;
  color: $black-700;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    margin: 0 0 50px 30vw;
    font-size: 18px;
  }
}

.vacancies__item {
  position: relative;
  display: flex;
  width: 100%;
  padding: 35px 95px;
  background: $white;
  border: 1px $gray-200 solid;
  transition: $UITransitionDefault;

  &:hover {
    background: transparent;
  }

  @include media-breakpoint-up(wide) {
    padding: 55px 105px;
  }

  @include media-breakpoint-up(extra-wide) {
    padding: 75px 110px;
  }

  @include media-breakpoint-down(tablet) {
    align-items: flex-end;
    padding: 25px;
    font-size: 26px;
    border-width: 2px;
  }
}

.vacancies__title {
  flex-grow: 1;
  margin: 0;
}

.vacancies__more {
  top: 0;
  left: 0;
  width: 20%;
  margin-left: 18px;

  &::before {
    @include stretch;

    content: '';
  }
}

.vacancies-form {
  @extend %vacancies-form;

  margin-top: 35px;
  padding: 0 30% 0 22%;

  @include media-breakpoint-down(tablet) {
    padding: 0;
  }
}
