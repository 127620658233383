.food-teasers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  & + & {
    margin-top: 55px;
  }

  @include media-breakpoint-down(tablet) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

.food-teasers__title {
  grid-column: 1 / -1;
  margin: 0;
}

$fontTeaser-horizontalPadding: 20px;

.food-teaser {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin: 0 15px 15px 0;
  padding: 0 $fontTeaser-horizontalPadding 40px;
  background: $white;

  %search-teasers & {
    width: calc(33.33% - (15px * 3 / 4));

    // stylelint-disable-next-line
    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(phone) {
    margin: 0;

    %search-teasers & {
      width: 100%;
    }
  }
}

.food-teaser__title {
  margin: 30px 0 10px;

  @include media-breakpoint-up(wide) {
    margin: 35px 0 20px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 40px 0 25px;
  }
}

.food-teaser__description {
  margin: 0 0 20px;
}

.food-teaser__weight {
  width: 100%;
  margin: 0 0 15px;
  color: $gray-500;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-up(wide) {
    margin: 0 0 30px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }
}

.food-teaser__parameters {
  margin-top: auto;
}

.food-teaser__options {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
  padding: 0;
  list-style: none;

  @include media-breakpoint-up(wide) {
    margin: 0 0 30px;
  }
}

.food-teaser__option {
  flex-shrink: 0;
}

.food-teaser__option-name {
  display: block;
}

.food-teaser__option-value {
  display: block;
  color: $gray;
}

.food-teaser__footer {
  @extend %foot-teaser__footer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.food-teaser__price {
  font-weight: bold;
}

.food-teaser__image-holder {
  position: relative;
  order: -1;
  margin: 0 ($fontTeaser-horizontalPadding * -1);

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    background-color: $gray-400;
    content: '';
  }
}

.food-teaser__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.food-teaser__buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.food-teaser__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 50%;

  img {
    z-index: 10;
  }

  &:first-child {
    margin-bottom: 10px;
  }
}

.food-teaser__favorite {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.food-teaser__share {
  margin: -4px;
}
