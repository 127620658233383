.select-interactive {
  $self: &;

  position: relative;
  z-index: 1;
  width: 275px;

  &--small {
    @extend %select--small;

    width: 100px;
  }

  &--normal {
    width: 177px;
  }

  &--filled {
    @extend %select--filled;
  }

  &--large {
    @extend %select--large;

    width: 275px;
    height: 60px;
    margin: 20px 0 30px;
    padding: 0 35% 0 0;
    font-weight: bold;
    font-size: 28px;
    font-family: $fontFamily-title;
    line-height: 30px;

    %contacts & {
      margin: 20px 0;
    }

    @include media-breakpoint-down(tablet) {
      width: 100%;
      padding: 0;
      font-size: 15px;
      line-height: 20px;
    }
  }

  &--fullWidth {
    width: 100%;

    &#{$self}--large {
      padding: 0;
    }

    %form & {
      width: 75%;
    }
  }

  &--border {
    @extend %select-interactive-border;
  }

  &--open {
    @extend %select-interactive-open;
  }

  @include media-breakpoint-up(wide) {
    &--small {
      width: 140px;
    }

    &--large {
      height: 80px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    &--small {
      width: 200px;
    }

    &--large {
      height: 100px;
    }
  }

  @include media-breakpoint-down(phone) {
    &--fullWidth {
      %form & {
        width: 100%;
      }
    }
  }
}

.select-toggle {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 7%;
  overflow: hidden;
  font-weight: 500;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  line-height: 40px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  background: $gray-200;
  border: 0;

  %select-interactive-border & {
    border: 1px solid $gray-400;
  }

  form & {
    background: $white;
  }

  %main-head & {
    background: $gray-200;
  }

  %select--large & {
    height: 60px;
    padding: 0 100px 0 50px;
    font-weight: bold;
    font-size: txf-relativeValue(28px, $unit: 'vw');
    background: transparent;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 6%;
    display: block;
    width: 6px;
    height: 6px;
    margin: -3px 0 0;
    border-color: $gray;
    border-style: solid;
    border-width: 0 0 1px 1px;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    transform-origin: center center;
    transition: transform $UITransitionFast;
    content: '';
  }

  %select--large &::after {
    right: 35px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
  }

  %select-interactive-open &::after {
    transform: rotate(-225deg);
  }

  %select-interactive-open & {
    font-weight: bold;
  }

  %select--small & {
    color: $gray-500;
  }

  // stylelint-disable
  %select--small%select--filled & {
    color: $black;
  }

  // stylelint-enable

  @include media-breakpoint-up(wide) {
    height: 70px;

    &::after {
      width: 10px;
      height: 10px;
      margin: -5px 0 0;
    }

    %select--small & {
      padding: 0 15%;
    }

    %select--large & {
      height: 70px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    %select--large & {
      height: 100px;
    }
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
    line-height: 1;

    %select--large & {
      padding: 0 25px;
      font-size: 15px;
    }

    %select--large &::after {
      right: 14px;
      width: 6px;
      height: 6px;
      margin-top: -3px;
    }
  }
}

.select-dropdown {
  @include styled-scrollbar;

  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 240px;
  margin: 0;
  overflow: auto;
  text-align: left;
  list-style: none;
  background: $white;
  visibility: hidden;
  opacity: 0;
  transition: visibility $UITransitionDefault step-end,
    opacity $UITransitionDefault;

  %select-interactive-border & {
    border-color: $gray-200;
    border-style: solid;
    border-width: 0 1px 1px;
  }

  %select--large & {
    width: 65%;
    background: $gray-200;
    border-color: $gray-400;
    border-style: solid;
    border-width: 0 1px 1px;
  }

  %contacts-select & {
    width: 100%;
  }

  %select-interactive-open & {
    visibility: visible;
    opacity: 1;
    transition: visibility $UITransitionDefault step-start,
      opacity $UITransitionDefault;
  }

  @include media-breakpoint-down(tablet) {
    %select--large & {
      width: 100%;
    }
  }
}

.select-dropdown__item {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 7%;
  font-weight: 500;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  line-height: 40px;
  border-color: $gray-200;
  border-style: solid;
  border-width: 0 0 1px;
  cursor: pointer;

  %select--large & {
    padding: 0 50px;
    font-size: txf-relativeValue(28px, $unit: 'vw');
    border-width: 0;
  }

  %select--large &::after {
    background-color: $gray-400;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $gray;
    opacity: 0;
    transition: opacity $UITransitionDefault;
    content: '';
  }

  &:hover {
    //stylelint-disable
    &::after {
      opacity: 1;
    }
    //stylelint-enable
  }

  input:checked + & {
    font-weight: bold;
    //stylelint-disable
    &::after {
      opacity: 1;
    }
    //stylelint-enable
  }

  @include media-breakpoint-up(wide) {
    height: 70px;

    %select--small & {
      padding: 0 15%;
      // color: $gray-500;
    }

    %select--large & {
      height: 70px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    %select--large & {
      height: 100px;
    }
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;

    %select--large & {
      padding: 0 25px;
      font-size: 15px;
    }
  }
}
