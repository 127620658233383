.basket-register {
  display: grid;
  grid-template-columns: repeat(10, minmax(80px, 1fr));
  column-gap: 18px;

  @include media-breakpoint-down(tablet) {
    display: flex;
    flex-direction: column;
  }
}

.basket-register__holder {
  @extend %basket-register__holder;

  display: flex;
  flex-direction: column;
  grid-column: 7 / span 4;
}

.order-final-price-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.order-final-price {
  display: flex;
  flex-wrap: wrap;
  margin: 35px 0 25px;
  font-weight: bold;
  font-size: txf-relativeValue(46px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    font-size: 26px;
  }
}

.order-final-total {
  margin-bottom: 15px;
  font-size: txf-relativeValue(16px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}
