.avatar__holder {
  grid-column: 1 / span 3;

  @include media-breakpoint-down(phone) {
    box-sizing: border-box;
    width: 50%;
    padding: 0 20px 0 0;
  }
}

.avatar__wrapper {
  @extend %avatar-wrapper;

  position: relative;
  width: 100%;
  background: $white;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 100% 0 0;
    content: '';
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

$photo-placeholder-size: 60px;

.avatar__placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $photo-placeholder-size;
  height: $photo-placeholder-size;
  margin: ($photo-placeholder-size / -2) 0 0 ($photo-placeholder-size / -2);
  transition: stroke $UITransitionDefault;
  stroke: $gray;

  %avatar-label & {
    stroke: $black;
  }
}

.avatar__placeholder-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar__delete-icon {
  width: 19px;
  height: 22px;
  margin: 0 12px 0 0;
  transition: stroke $UITransitionDefault;
  stroke: $gray-500;

  %button--text-hover & {
    stroke: $black;
  }
}

.avatar__label {
  height: 40px;
  color: $gray;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  transition: color $UITransitionDefault;

  %avatar-wrapper & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    @extend %avatar-label;

    color: $black;
  }
}
