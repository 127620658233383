$more-width: 150px;
$more-reduced-width: 50px;
$more-offset: $more-width - $more-reduced-width;

.more {
  display: block;
  width: $more-width;
  color: $black;

  %slider-is-observable & {
    left: -50px;
    transition: opacity $UITransitionSlow, left 0.4s;
  }

  %swiper-slide & {
    left: -50px;
    opacity: 0;
    pointer-events: none;
  }

  %swiper-slide-is-active & {
    left: 0;
    opacity: 1;
    pointer-events: all;
  }

  %slider-observable-finished & {
    left: 0;
  }

  %home-slider-preview & {
    bottom: 70px;
    left: 10%;
  }

  &:hover {
    @extend %more-hover;
  }

  @include media-breakpoint-up(wide) {
    width: 200px;
  }

  @include media-breakpoint-up(extra-wide) {
    width: 300px;

    %home-slider-preview & {
      bottom: 120px;
    }
  }

  @include media-breakpoint-down {
    width: $more-reduced-width;
  }
}

.more-icon {
  width: $more-width;
  height: 100%;
  fill: none;
  stroke: currentColor;

  @include media-breakpoint-up(wide) {
    width: 200px;
  }

  @include media-breakpoint-up(extra-wide) {
    width: 300px;
  }
}

.more-icon__shaft {
  stroke-dasharray: $more-width;
  stroke-dashoffset: $more-offset;
  transition: $UITransitionDefault;

  %slider-is-observable & {
    stroke-dashoffset: $more-width;
    transition: stroke-dashoffset $UITransitionMedium;
  }

  %slider-observable-finished & {
    stroke-dashoffset: $more-offset;
  }

  @include media-hover {
    %more-hover & {
      stroke-dashoffset: 0;
    }
  }
}

.more-icon__head {
  transform: translateX($more-offset * -1);
  transition: $UITransitionDefault;

  %slider-is-observable & {
    transform: translateX(($more-width) * -1);
    opacity: 0;
    transition: opacity $UITransitionSlow, transform $UITransitionMedium;
  }

  %slider-observable-finished & {
    transform: translateX($more-offset * -1);
    opacity: 1;
  }

  @media (hover) {
    %more-hover & {
      transform: translateX(0);
    }
  }
}
