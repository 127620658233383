.contacts {
  @extend %contacts;

  padding: 0 90px 0 28%;

  @include media-breakpoint-down(tablet) {
    padding: 0;
  }
}

.contacts__general {
  @include media-breakpoint-up(wide) {
    margin: 0 0 100px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: 0 0 150px;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0 0 0 30vw;
  }
}

.contacts-select {
  @extend %contacts-select;
}

.contacts__restaurants {
  @include media-breakpoint-down(phone) {
    padding: 25px;
    background: $white;
  }
}

.contacts__article {
  @extend %contacts__article;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 18%;
  background-color: $white;

  @include media-breakpoint-up(wide) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  @include media-breakpoint-up(extra-wide) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include media-breakpoint-down(phone) {
    padding: 0;
  }
}

.contacts__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 45px;
  padding: 0;
  list-style: none;

  @include media-breakpoint-up(wide) {
    margin-bottom: 70px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-down(phone) {
    flex-direction: column;

    %contacts__article & {
      margin: 20px 0 30px;
    }
  }
}

.contacts__item {
  display: flex;
  flex-direction: column;
  width: 27%;
  margin: 0 6% 40px 0;

  @include media-breakpoint-down(tablet) {
    width: 45%;
    margin-right: 5%;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
  }
}

.contacts__link {
  color: $black-700;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
    text-decoration: underline;
  }
}

.contacts__title {
  margin: 0;

  @include media-breakpoint-down(tablet) {
    font-size: 35px;
  }
}

.contacts__select {
  width: 100%;
  margin: 0 0 20px;

  @include media-breakpoint-down(phone) {
    height: 40px;
  }
}

.contacts__logo {
  max-width: 120px;
  max-height: 60px;
  margin-top: 10px;

  @include media-breakpoint-down(phone) {
    margin-top: 15px;
  }
}

.contacts__button {
  width: 35%;

  @include media-breakpoint-down(phone) {
    width: 100px;
  }
}

.contacts__info {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(extra-wide) {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-down(phone) {
    flex-wrap: wrap;
  }
}

.contacts__parameter {
  width: 25%;
  margin: 0;

  @include media-breakpoint-down(phone) {
    width: 100%;
    margin-bottom: 5px;
  }
}

.contacts__data {
  width: 75%;
  color: $black-700;
  font-size: txf-relativeValue(14px, $unit: 'vw');
  text-decoration: none;

  @include media-breakpoint-down(tablet) {
    font-size: 14px;
  }

  @include media-breakpoint-down(phone) {
    width: 100%;
    font-size: 13px;
  }
}

.contacts__socials {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.contacts__social {
  color: $gray-500;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  text-decoration: none;

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}

.contacts__map {
  width: 100%;

  @include media-breakpoint-down(phone) {
    height: 200px;
  }
}

.contacts__map-image {
  width: 100%;
  height: 100%;
}
