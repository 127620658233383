.autocomplete {
  $self: &;

  position: relative;
  z-index: 1;
  display: block;

  &--error {
    @extend %autocomplete-error;
  }

  %form-row-content-column > & {
    // stylelint-disable-next-line
    & + #{$self} {
      margin: 20px 0 0;
    }
  }

  %account-address-form-wrapper > & {
    // stylelint-disable-next-line
    & + #{$self} {
      margin: 0 18px 0 0;
    }
  }

  %delivery-custom-address & {
    display: flex;
    flex-direction: column;
    width: 300px;

    @include media-breakpoint-down(phone) {
      width: 100%;
    }
  }
}

.autocomplete__error-text {
  color: $error-color;
  font-size: txf-relativeValue(12px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    font-size: 12px;
  }
}

.autocomplete-label {
  color: $gray-800;
  font-size: txf-relativeValue(12px, $unit: 'vw');
  line-height: 20px;

  %autocomplete-error & {
    @extend %form__row--error;

    color: $error-color;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 12px;
  }
}

.autocomplete-options {
  @include styled-scrollbar;

  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 160px;
  margin: 0;
  padding: 0;
  overflow: scroll;
  list-style: none;
  background: $white;
  border-color: $gray-400;
  border-style: solid;
  border-width: 0 1px 1px;
  z-index: 100;
}

.autocomplete-option {
  width: 100%;
  padding: 10px 25px;
  line-height: 20px;
  text-align: left;
  background: none;
  border: 0;

  &--active {
    background: $gray-200;
  }
}
