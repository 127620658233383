.delivery-type-wrapper {
  grid-column: 3 / -1;
}

.delivery-type__label {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    background: $white;
    border: 1px solid $gray;
    border-radius: 50%;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 11px;
    display: block;
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    background: $black;
    border-radius: 50%;
    transform: scale(0);
    transition: transform $UITransitionDefault;
    content: '';
  }

  input:checked + &::after {
    transform: scale(1);
  }

  %form-row ~ & {
    margin: 0 0 0 18px;
  }

  & ~ & {
    margin: 0 0 0 30px;
  }
}

.address-notification {
  color: #e32636;
}
