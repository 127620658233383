.team-school {
  display: flex;
  flex-direction: column;
}

.team-school__list {
  margin: 65px 0 160px;
  padding-left: 30%;
  list-style: none;

  @include media-breakpoint-up(wide) {
    margin-bottom: 250px;
  }

  @include media-breakpoint-down(phone) {
    margin: 0 0 260px;
    padding-left: 30vw;
  }
}

.team-school__item {
  &:not(:last-child) {
    margin-bottom: 110px;
  }

  @include media-breakpoint-down(phone) {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }
}

.team-school__text {
  margin: -40px 0 0 5px;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-up(wide) {
    margin: -50px 0 0 5px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin: -70px 0 0 5px;
  }

  @include media-breakpoint-down(tablet) {
    margin: 0 0 5px;
    font-size: 20px;
  }
}

.team-school__offer {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  margin-top: -75px;
  padding: 60px 100px;
  background: $white;
  transform: translateY(60px);
  opacity: 1;
  transition: transform $UITransitionSlowest, opacity $UITransitionSlow;

  &--intersected {
    transform: translateY(0);
    opacity: 1;
  }

  &:nth-child(odd) {
    align-self: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 260px;
  }

  @include media-breakpoint-up(wide) {
    &:not(:last-child) {
      margin-bottom: 260px;
    }
  }

  @include media-breakpoint-up(extra-wide) {
    &:not(:last-child) {
      margin-bottom: 300px;
    }
  }

  @include media-breakpoint-down(tablet) {
    padding: 60px 50px 60px 100px;
  }

  @include media-breakpoint-down(phone) {
    align-self: flex-start;
    width: calc(100% + 30px);
    padding: 220px 30px 55px;

    &:nth-child(odd) {
      align-self: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: 260px;
    }
  }
}

.team-school__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  width: 55%;
  text-align: left;

  @include media-breakpoint-down(phone) {
    align-self: flex-start;
    width: 90%;
    text-align: left;
  }
}

.team-school__title {
  margin: 0 0 30px;
}

.team-school__socials {
  display: flex;
  align-self: flex-start;
  padding: 0;
  list-style: none;
}

.team-school__social {
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 15px;
  padding-right: 15px;
  color: $gray-800;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  border-right: 1px solid $gray-450;

  &:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.team-school__link {
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 15px;
  padding-right: 15px;
  color: $gray-800;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  border-right: 1px solid $gray-450;

  &:last-child {
    margin: 0;
    padding: 0;
    border: 0;
  }

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.team-school__description {
  width: 100%;
  margin-bottom: 60px;

  > ul {
    align-self: flex-start;
    padding-left: 15px;
    list-style-image: url('../../icons/bullet.svg');
  }

  > li {
    margin-bottom: 17px;
  }

  @include media-breakpoint-up(wide) {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(extra-wide) {
    margin-bottom: 100px;
  }
}

.team-school__image-holder {
  position: absolute;
  top: -50px;
  left: 0;
  width: 40%;
  height: 100%;

  @include media-breakpoint-down(phone) {
    top: -200px;
    left: -30px;
    width: 90%;
    height: 60%;
  }
}

.team-school__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
