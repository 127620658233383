.vacancy {
  margin-bottom: 55px;
  padding: 0 90px 0 20%;
  font-size: txf-relativeValue(14px, $unit: 'vw');

  @include media-breakpoint-down(tablet) {
    padding: 0 0 0 30vw;
    font-size: 14px;
  }
}

.vacancy__point {
  margin: 0 0 50px;

  @include media-breakpoint-down(tablet) {
    margin: 0 0 25px;
  }
}

.vacancy__title {
  margin: 35px 0 10px;
}

.vacancy__salary {
  margin-bottom: 60px;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;
  font-style: italic;

  @include media-breakpoint-down(tablet) {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
  }
}

.vacancy__term {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: txf-relativeValue(20px, $unit: 'vw');
  font-family: $fontFamily-title;

  @include media-breakpoint-down(tablet) {
    margin-bottom: 5px;
    font-size: 15px;
  }
}

.vacancy__definition {
  margin: 0;

  &:not(:last-child) {
    margin: 0 0 50px;
  }

  @include media-breakpoint-down(tablet) {
    font-size: 13px;
  }
}

.vacancy__list {
  margin: 15px 0 0;
  padding: 0;
  font-family: $fontFamily-text;
  list-style: none;
}

.vacancy__item {
  margin-bottom: 20px;
}

.vacancy__map {
  width: 100%;
  padding: 0 90px 0 270px;

  @include media-breakpoint-down(tablet) {
    padding: 0;
  }
}

.vacancy__map-image {
  width: 100%;
  height: 100%;
}
