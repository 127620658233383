@mixin stretch($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin stretchHeight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100%;
}
