.card-item {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  background: $white;

  & + & {
    margin: 8px 0 0;
  }
}

.card-image__holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
  margin: 0 18px 0 0;
  border: 1px solid $gray;
  border-radius: 5px;
}

.card-image--visa {
  width: 35px;
  height: 12px;
}

.card-image--mastercard {
  width: 24px;
  height: 15px;
}

.card-number {
  font-weight: 500;
  line-height: 60px;
}

.card-delete {
  @include text-replace;

  position: relative;
  flex-shrink: 0;
  width: 50px;
  margin: 0 0 0 auto;
  padding: 0;
  overflow: hidden;
  line-height: 60px;
  background: none;
  border: 0;

  &:hover {
    @extend %address-item-delete-hover;
  }
}

$card-delete-icon: 20px;

.card-delete__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $card-delete-icon;
  height: $card-delete-icon;
  margin: ($card-delete-icon / -2) 0 0 ($card-delete-icon / -2);
  transition: $UITransitionDefault;
  stroke: $gray;

  %address-item-delete-hover & {
    stroke: $black;
  }
}
