/* Bodoni */

@font-face {
  font-weight: normal;
  font-family: 'Bodoni';
  font-style: normal;
  src: url('./bodoni/bodoni-regular.woff2') format('woff2');
}

@font-face {
  font-weight: normal;
  font-family: 'Bodoni';
  font-style: italic;
  src: url('./bodoni/bodoni-regular-italic.woff2') format('woff2');
}

@font-face {
  font-weight: bold;
  font-family: 'Bodoni';
  font-style: normal;
  src: url('./bodoni/bodoni-bold.woff2') format('woff2');
}

@font-face {
  font-weight: bold;
  font-family: 'Bodoni';
  font-style: italic;
  src: url('./bodoni/bodoni-bold-italic.woff2') format('woff2');
}

/* Gilroy */

@font-face {
  font-weight: 100;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-thin.woff2') format('woff2');
}

@font-face {
  font-weight: 100;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-thin-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 200;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-extralight.woff2') format('woff2');
}

@font-face {
  font-weight: 200;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-extralight-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 300;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-light.woff2') format('woff2');
}

@font-face {
  font-weight: 300;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-light-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 400;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-regular.woff2') format('woff2');
}

@font-face {
  font-weight: 400;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-regular-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 500;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-medium.woff2') format('woff2');
}

@font-face {
  font-weight: 500;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-medium-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 600;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-semibold.woff2') format('woff2');
}

@font-face {
  font-weight: 600;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-semibold-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-bold.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-bold-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 800;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-extrabold.woff2') format('woff2');
}

@font-face {
  font-weight: 800;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-extrabold-italic.woff2') format('woff2');
}

@font-face {
  font-weight: 900;
  font-family: 'Gilroy';
  font-style: normal;
  src: url('./gilroy/gilroy-black.woff2') format('woff2');
}

@font-face {
  font-weight: 900;
  font-family: 'Gilroy';
  font-style: italic;
  src: url('./gilroy/gilroy-black-italic.woff2') format('woff2');
}
