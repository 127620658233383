.photo {
  margin-right: 0;
  margin-left: 0;

  @include media-breakpoint-down(tablet) {
    margin-right: auto;
    margin-left: auto;
  }
}

.photo__slide-wrapper {
  &::before {
    position: relative;
    display: block;
    width: 100%;
    padding: 66% 0 0;
    content: '';
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  %team-slider {
    @extend %photo__slide-wrapper-team;
  }
}

%photo__slide-wrapper-team {
  img {
    @include media-breakpoint-down(phone) {
      display: block;
    }
  }
}
