.search-filter {
  display: flex;

  @include media-breakpoint-down(tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    scroll-padding: 0 30px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    justify-content: flex-start;
    width: 100vw;
    max-width: unset;
    padding: 10px 30px;
    overflow: scroll;
    transform: none;
  }

  @include media-breakpoint-down(phone) {
    margin: 0 0 0 ($layout-phone-horizontalIndent * -1);
  }
}

.search-filter__checkbox {
  min-width: 170px;
  margin: 5px;
  font-weight: 400;

  & + & {
    margin: 5px;
  }

  @include media-breakpoint-down(tablet) {
    flex-shrink: 0;
    scroll-snap-align: start;

    &:last-of-type {
      box-sizing: border-box;
      padding: 0 30px 0 0;
    }
  }
}

.search-filter__checkbox-label {
  padding: 0 25px;
  line-height: 40px;
  text-align: center;
  border: 1px solid $gray;
  transition: border-color $UITransitionDefault;

  &::before {
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: $white;
    opacity: 0;
    transition: opacity $UITransitionDefault;
    content: '';
  }

  input:checked + & {
    border-color: $white;
    //stylelint-disable-next-line
    &::before {
      opacity: 1;
    }
  }
}
