$body-background: $gray-200;

body {
  overflow-x: hidden;
  color: $black;
  font-size: txf-relativeValue(16px, $unit: 'vw');
  font-family: $fontFamily-text;
  background: $body-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-down(tablet) {
    font-size: 16px;
  }
}

label {
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

input {
  appearance: none;

  &:focus {
    outline-color: $black;
  }
}

button {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}
