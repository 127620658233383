.home-restaurants-slider {
  @extend %home-restaurants-slider;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  &%swiper-container {
    width: 100vw;
    margin: 0 0 0 (calc((100vw - #{var(--max-width)}) / -2));
  }

  .swiper-wrapper {
    // top: 130px;
    // height: 200px !important;
    height: calc(var(--section-width) * 0.313);
  }

  @include media-breakpoint-down(tablet) {
    width: 100%;
    margin: 0;
    padding: ($slider-height-tablet / 2) 0;

    &%swiper-container {
      width: 100%;
      margin: 0;
    }
  }

  @include media-breakpoint-down(phone) {
    .swiper-wrapper {
      height: 100px !important;
    }
  }
}

.home-restaurants-slider-slide {
  transform: translateY(0);
  transition: transform $UITransitionSlow;

  %swiper-slide-is-prev & {
    transform: translateY(calc(50% - 75px));
  }

  %swiper-slide-is-next & {
    transform: translateY(calc(-50% + 125px));
  }

  @include media-breakpoint-down(phone) {
    %swiper-slide-is-prev & {
      transform: translateY(calc(50% - 45px));
    }

    %swiper-slide-is-next & {
      transform: translateY(calc(-50% + 105px));
    }
  }
}
